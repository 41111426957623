import { GroupDataType } from '../utils/functions/types'
import GroupCard from './group_card/GroupCard'
import { MdAdd } from 'react-icons/md'
import { usePopup } from '../utils/hooks/usePopup'
import Popup from '../utils/components/popup/Popup'
import NewGroup from './NewGroup'
import { createGroup } from '../utils/functions/firebase/groupManagement'
import { useNavigate } from 'react-router'
import { useStatusMessage } from '../utils/hooks/useStatusMessage'
import Status from '../utils/components/Status'
import Loading from '../utils/components/loading/LoadingFullPage'

type Props = {
  groups: GroupDataType[]
}

function Groups({ groups }: Props) {
  const [popup, popupContent, openPopup, closePopup] = usePopup(false)
  const [statusMessage, openStatusMessage] = useStatusMessage({
    message: [],
    type: null,
  })
  const navigate = useNavigate()
  const handleCreateGroup = async (
    name: string,
    description: string,
    giftPerUser: string
  ) => {
    closePopup()
    let groupId = await createGroup(
      name,
      description,
      giftPerUser,
      openStatusMessage
    )
    navigate(`/group/${groupId}`)
  }

  return groups ? (
    <div className="flex w-full flex-col gap-2 py-4">
      <Status statusMessage={statusMessage} />
      <div className="grid w-full auto-rows-fr gap-4 md:grid-cols-2 xl:grid-cols-3">
        {groups.map((group) => (
          <GroupCard key={group.id} group={group} />
        ))}
        <button
          className="group flex h-full w-full cursor-pointer items-center rounded-lg border border-light-primary-2 bg-light-primary-0 shadow duration-200 hover:bg-light-accent-2 dark:border-dark-primary-2 dark:bg-dark-primary-0 dark:hover:bg-dark-accent-2"
          onClick={() =>
            openPopup(<NewGroup handleCreateGroup={handleCreateGroup} />)
          }
        >
          <div
            style={{ fontSize: '5rem' }}
            className="flex h-[375px] w-full items-center justify-center text-light-accent-2 duration-200 group-hover:text-light-primary-0 dark:text-dark-accent-2 dark:group-hover:text-light-primary-0"
          >
            <MdAdd />
          </div>
        </button>
        <Popup isOpened={popup} close={closePopup}>
          {popupContent}
        </Popup>
      </div>
    </div>
  ) : (
    <Loading />
  )
}

export default Groups
