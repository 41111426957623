export type TooltipProps = {
  text: string
  fontSize?: string
  bottom?: boolean
  distance?: string
}

function Tooltip({ text, fontSize, bottom, distance }: Readonly<TooltipProps>) {
  const d = distance ?? '20%'
  return (
    <div
      role="tooltip"
      className="invisible absolute right-1/2 z-50 inline-block rounded bg-light-primary-2 p-2 text-xs font-medium text-light-secondary-1 opacity-0 shadow-sm duration-200 peer-hover:visible peer-hover:opacity-90 peer-hover:delay-300 dark:bg-dark-primary-2 dark:text-dark-secondary-1"
      style={
        bottom
          ? {
              bottom: 'calc(-50% - ' + d + ')',
              transform: `translate(50%,50%)`,
            }
          : {
              top: 'calc(-50% - ' + d + ')',
              transform: `translate(50%,-50%)`,
            }
      }
    >
      <p className="text-center" style={{ fontSize: fontSize || '0.8rem' }}>
        {text}
      </p>
      <div
        className={
          'absolute right-1/2 h-2 w-2 translate-x-1/2 rotate-45 bg-light-primary-2 dark:bg-dark-primary-2' +
          (bottom ? ' top-0 -translate-y-1/2' : ' bottom-0 translate-y-1/2')
        }
      ></div>
    </div>
  )
}

export default Tooltip
