import { useTranslation } from 'react-i18next'

type Props = {
  text: string
  onAccept: () => void
  close: () => void
}

function ConfirmationContent({ text, onAccept, close }: Readonly<Props>) {
  const { t } = useTranslation()
  const handleOnAccept = () => {
    onAccept()
    close()
  }
  return (
    <div className="w-full">
      <p className="p-4 text-center text-xl text-light-secondary-0 dark:text-dark-secondary-0">
        {text}
      </p>
      <div className="flex w-full flex-row items-center justify-evenly gap-2">
        <button
          onClick={handleOnAccept}
          className="rounded-lg bg-light-accent-2 px-6 py-1 text-xl text-light-primary-0 duration-200 hover:bg-light-accent-1 focus:outline-none focus:ring-4 focus:ring-light-accent-3 dark:bg-dark-accent-2 dark:hover:bg-dark-accent-1 focus:dark:ring-dark-accent-3 md:py-2 md:text-2xl"
        >
          {t('yes')}
        </button>
        <button
          onClick={close}
          className="rounded-lg border border-light-primary-3 bg-light-primary-0 px-6 py-1 text-xl text-light-secondary-0 duration-200 hover:bg-light-primary-2 focus:outline-none focus:ring-4 focus:ring-light-primary-2 dark:border-dark-primary-3 dark:bg-dark-primary-1 dark:text-dark-secondary-0 dark:hover:bg-dark-primary-2 dark:focus:ring-dark-primary-2 md:py-2 md:text-2xl"
        >
          {t('no')}
        </button>
      </div>
    </div>
  )
}

export default ConfirmationContent
