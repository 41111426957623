import { Dispatch, SetStateAction, useState } from 'react'
import { Infos } from '../../utils/functions/types'

export const useInfos = (): [
  infos: Infos,
  setName: Dispatch<SetStateAction<string>>,
  setFirstName: Dispatch<SetStateAction<string>>,
  setPhone: (phone: string) => void,
  resetInputs: () => void
] => {
  const [name, setName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [phone, setPhone] = useState('')
  const Logins = { name, firstName, phone }
  const resetInputs = (): void => {
    setName('')
    setFirstName('')
    setPhone('')
  }

  return [Logins, setName, setFirstName, setPhone, resetInputs]
}
