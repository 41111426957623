type Props = {
  unseenCount: number
  forceFullSize?: boolean
}

function NotificationCountWidget({
  unseenCount,
  forceFullSize,
}: Readonly<Props>) {
  const fontSize = forceFullSize
    ? ' right-1 top-1 h-4 w-4 text-xs'
    : ' right-[6px] top-[6px] h-3 w-3 text-[0.6rem]/[0.7rem] sm:right-1 sm:top-1 sm:h-4 sm:w-4 sm:text-xs'
  return (
    unseenCount !== 0 && (
      <div
        className={`absolute  ${fontSize} rounded-full bg-light-accent-2 font-semibold text-light-primary-2 duration-200 group-hover:bg-light-primary-0 group-hover:text-light-accent-2 dark:text-dark-secondary-2 dark:group-hover:bg-dark-secondary-0 dark:group-hover:text-dark-accent-2`}
      >
        {unseenCount}
      </div>
    )
  )
}

export default NotificationCountWidget
