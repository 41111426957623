import { UserDataType } from '../utils/functions/types'
import { getFullName } from '../utils/functions/firebase/user'
import DisplayEditField from './display_edit_field/DisplayEditField'
import { useTranslation } from 'react-i18next'
import InformationPage from '../shared/information_page/InformationPage'
import InformationPageItem from '../shared/information_page/InformationPageItem'
import { useConfirmation } from '../utils/hooks/useConfirmation'
import { useStatusMessage } from '../utils/hooks/useStatusMessage'
import LoadingFullPage from '../utils/components/loading/LoadingFullPage'
import { useState } from 'react'
import { useUserFunctions } from './hooks/useUserFunctions'
import { isAccountFromProvider } from '../utils/functions/firebase/auth'
import BareDisplayEditField from './display_edit_field/BareDisplayEditField'
import Input from '../utils/components/Input'
import { FcGoogle } from 'react-icons/fc'
import { BiSolidLockAlt } from 'react-icons/bi'
import IconButton from '../utils/components/IconButton'

function Profile({ userData }: Readonly<{ userData: UserDataType }>) {
  const { t } = useTranslation()
  const [
    confirmation,
    confirmationContent,
    openConfirmation,
    closeConfirmation,
  ] = useConfirmation(false)
  const [statusMessage, setStatusMessage] = useStatusMessage({
    message: [],
    type: null,
  })
  const userFunctions = useUserFunctions(
    userData,
    openConfirmation,
    setStatusMessage
  )
  const [firstName, setFirstName] = useState(userData?.firstName)
  const [name, setName] = useState(userData?.name)
  const [email, setEmail] = useState(userData?.email)
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState(userData?.phone)
  return userData && userFunctions ? (
    <InformationPage
      title={getFullName(userData)}
      subTitle={userData.email}
      confirmation={{
        isOpened: confirmation,
        content: confirmationContent,
        close: closeConfirmation,
      }}
      statusMessage={statusMessage}
    >
      <InformationPageItem title={t('informations')}>
        <div className="items-between flex flex-col justify-center gap-2 px-4">
          <DisplayEditField
            type="text"
            baseValue={userData?.firstName}
            fieldName={t('firstName')}
            fieldValue={firstName}
            setFieldValue={setFirstName}
            handleModification={userFunctions.modifyFirstName}
            setStatusMessage={setStatusMessage}
          />
          <DisplayEditField
            type="text"
            baseValue={userData?.name}
            fieldName={t('name')}
            fieldValue={name}
            setFieldValue={setName}
            handleModification={userFunctions.modifyName}
            setStatusMessage={setStatusMessage}
          />
          {isAccountFromProvider() ? (
            <BareDisplayEditField fieldName={t('email')}>
              <div className="w-full sm:w-[36rem]">
                <Input
                  type="email"
                  value={email}
                  onChange={setEmail}
                  placeholder={userData?.email}
                  noLabel
                  disabled
                  important
                  sideAction={
                    <div className="flex flex-row items-center">
                      <IconButton
                        icon={<BiSolidLockAlt />}
                        fontSize="2rem"
                        tooltip={{
                          text: t('unmodifiable'),
                          distance: '12px',
                        }}
                      />
                      <IconButton
                        icon={<FcGoogle />}
                        tooltip={{
                          text: 'Google',
                          distance: '10%',
                        }}
                      />
                    </div>
                  }
                />
              </div>
            </BareDisplayEditField>
          ) : (
            <DisplayEditField
              type="text"
              baseValue={userData?.email}
              fieldName={t('email')}
              fieldValue={email}
              setFieldValue={setEmail}
              handleModification={userFunctions.modifyEmail}
              setStatusMessage={setStatusMessage}
            />
          )}
          {!isAccountFromProvider() && (
            <DisplayEditField
              type="password"
              baseValue={''}
              fieldName={t('password')}
              fieldValue={password}
              setFieldValue={setPassword}
              handleModification={userFunctions.modifyPassword}
              setStatusMessage={setStatusMessage}
            />
          )}
          <DisplayEditField
            type="text"
            baseValue={userData?.phone}
            fieldName={t('phone')}
            fieldValue={phone}
            setFieldValue={setPhone}
            handleModification={userFunctions.modifyPhone}
            setStatusMessage={setStatusMessage}
          />
        </div>
      </InformationPageItem>
      <InformationPageItem title={t('manageAccount')}>
        <div className="items-between flex flex-col justify-center gap-2 px-4">
          <button
            className="flex w-full items-center justify-center rounded-lg border border-light-primary-3 bg-light-primary-2 p-8 text-center text-light-secondary-1 duration-200 hover:bg-light-primary-3 focus:outline-none focus:ring-4 focus:ring-light-primary-2 dark:border-dark-primary-3 dark:bg-dark-primary-2 dark:text-dark-secondary-1 dark:hover:bg-dark-primary-3 dark:focus:ring-dark-primary-2 md:p-4"
            onClick={userFunctions.requestData}
          >
            <p className="text-xl font-medium">{t('requestUserData')}</p>
          </button>
          <button
            className="flex w-full items-center justify-center rounded-lg bg-light-accent-2 p-8 text-center text-light-primary-0 duration-200 hover:bg-light-accent-1 focus:outline-none focus:ring-4 focus:ring-light-accent-1 dark:bg-dark-accent-2 dark:hover:bg-dark-accent-1 dark:focus:ring-dark-accent-1 md:p-4"
            onClick={userFunctions.deleteAccount}
          >
            <p className="text-xl font-medium text-light-primary-0">
              {t('deleteAccount')}
            </p>
          </button>
        </div>
      </InformationPageItem>
    </InformationPage>
  ) : (
    <LoadingFullPage />
  )
}

export default Profile
