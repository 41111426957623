import { FormEvent, useEffect, useState } from 'react'
import { useStatusMessage } from '../../../hooks/useStatusMessage'
import Status from '../../Status'
import Input from '../../Input'
import { StatusMessage } from '../../../functions/types'

type Props = {
  inputs: { [input: string]: 'email' | 'password' | 'text' }
  onSubmit: (
    inputValues: { [input: string]: string },
    openStatusMessage: (status: StatusMessage) => void
  ) => void
  title: string
  placeholder: string
  submitText: string
}

function SimplePopupInputForm({
  inputs,
  onSubmit,
  title,
  placeholder,
  submitText,
}: Readonly<Props>) {
  const [inputValues, setInputValues] = useState<{
    [input: string]: string
  }>({})
  const [statusMessage, openStatusMessage] = useStatusMessage({
    message: [],
    type: null,
  })
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(inputValues, openStatusMessage)
  }
  useEffect(() => {
    for (const input in inputs) {
      setInputValues((prev) => ({ ...prev, [input]: '' }))
    }
  }, [inputs])

  return (
    <div className="mb-4 flex w-full flex-col items-center justify-center">
      <div className="w-full px-6">
        <Status statusMessage={statusMessage} />
      </div>
      <p className="mb-4 text-center text-2xl text-light-secondary-0 dark:text-dark-secondary-0">
        {title}
      </p>
      <form
        className="flex w-full flex-col items-center justify-center gap-4 sm:flex-row"
        onSubmit={(e) => handleSubmit(e)}
      >
        {inputValues &&
          Object.keys(inputs).map((input) => {
            return (
              inputValues[input] !== undefined && (
                <Input
                  key={input}
                  type={inputs[input]}
                  value={inputValues[input]}
                  onChange={(value: string) =>
                    setInputValues((prev) => ({ ...prev, [input]: value }))
                  }
                  placeholder={placeholder}
                />
              )
            )
          })}
        <button className="w-full rounded-lg bg-light-accent-2 px-6 py-2 text-center text-lg text-light-primary-0 duration-200 hover:bg-light-accent-1 focus:outline-none focus:ring-4 focus:ring-light-accent-3 dark:bg-dark-accent-2 dark:hover:bg-dark-accent-1 focus:dark:ring-dark-accent-3 xs:w-auto sm:h-12">
          {submitText}
        </button>
      </form>
    </div>
  )
}

export default SimplePopupInputForm
