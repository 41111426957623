import { ReactNode, useCallback, useState } from 'react'

export const usePopup = (
  isInitiallyOpen: boolean
): [
  popup: boolean,
  content: ReactNode,
  open: (children: ReactNode) => void,
  close: () => void,
] => {
  const [popup, setPopup] = useState<boolean>(isInitiallyOpen)
  const [content, setContent] = useState<ReactNode>(null)
  const open = useCallback(
    (children: ReactNode) => {
      setContent(children)
      setPopup(true)
    },
    [setPopup]
  )
  const close = useCallback(() => {
    setPopup(false)
  }, [setPopup])

  return [popup, content, open, close]
}
