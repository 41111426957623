import { t } from 'i18next'
import { checkGroupDescription } from '../../utils/functions/checkValues'
import { StatusMessage } from '../../utils/functions/types'
import SimplePopupInputForm from '../../utils/components/popup/popup_content/SimplePopupInputForm'

function ModifyGroupDescription({
  handleModifyDescription,
}: Readonly<{
  handleModifyDescription: (name: string) => void
}>) {
  const handleSubmit = (
    inputs: { [input: string]: string },
    openStatusMessage: (status: StatusMessage) => void
  ) => {
    let status = checkGroupDescription(inputs['description'])
    if (status.type === 'error') {
      openStatusMessage(status)
    } else {
      handleModifyDescription(inputs['description'])
    }
  }
  return (
    <SimplePopupInputForm
      inputs={{ description: 'text' }}
      onSubmit={handleSubmit}
      title={t('modifyGroupDescription')}
      placeholder={t('description')}
      submitText={t('modify')}
    />
  )
}

export default ModifyGroupDescription
