type Props = {
  children: React.ReactNode
  title: string
}

function InformationPageItem({ children, title }: Readonly<Props>) {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-4">
      <p className="w-full border-b border-b-light-primary-3 font-cookie text-4xl text-light-secondary-2 duration-200 dark:border-b-dark-primary-3 dark:text-dark-secondary-2 sm:text-5xl">
        {title}
      </p>
      {children}
    </div>
  )
}

export default InformationPageItem
