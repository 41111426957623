import { useState } from 'react'
import GroupTabButton from './GroupTabButton'
import GroupTabSummary from './GroupTabSummary'
import { GroupCardTab, GroupDataType } from '../../utils/functions/types'
import GroupTabMembers from './GroupTabMembers'
import { useMembers } from './hooks/useMembers'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { db } from '../../utils/functions/firebase/auth'

type Props = {
  group: GroupDataType
}

function GroupCard({ group }: Readonly<Props>) {
  const [tab, setTab] = useState<GroupCardTab>(0)
  const [owner, members] = useMembers(db, group)
  const { t } = useTranslation()
  return (
    <div className="relative overflow-hidden rounded-lg border border-light-primary-2 bg-light-primary-0 shadow dark:border-dark-primary-2 dark:bg-dark-primary-0">
      <ul className="relative flex rounded-t-lg border-b border-light-primary-3 text-center text-sm font-medium dark:border-dark-primary-3">
        <GroupTabButton
          text="summary"
          selected={tab === 0}
          onClick={() => setTab(0)}
        />
        <GroupTabButton
          text="members"
          selected={tab === 1}
          onClick={() => setTab(1)}
        />
        <div
          style={{ translate: `${tab * 100}%` }}
          className="absolute bottom-0 h-[2px] w-1/3 bg-light-accent-2 duration-200 dark:bg-dark-accent-2 md:w-1/4"
        ></div>
      </ul>
      <div
        style={{ translate: `-${tab * 100}%` }}
        className="flex h-[320px] flex-col flex-wrap duration-200"
      >
        <GroupTabSummary
          setTab={setTab}
          group={group}
          owner={owner}
          members={members}
        />
        <GroupTabMembers group={group} owner={owner} members={members} />
      </div>
      <Link
        to={`/group/${group.id}`}
        className="absolute bottom-6 right-6 rounded-md bg-light-accent-2 px-4 py-2 font-medium text-light-primary-0 duration-200 hover:bg-light-accent-3 dark:bg-dark-accent-2 dark:text-light-primary-0 dark:hover:bg-dark-accent-1"
      >
        {t('seeGroup')}
      </Link>
    </div>
  )
}

export default GroupCard
