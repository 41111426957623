import { UserDataType } from '../../utils/functions/types'
import { useNotifications } from '../dropdowns/notifications/hooks/useNotifications'
import NotificationCountWidget from './NotificationCountWidget'

type Props = {
  userData: UserDataType
  forceFullSize?: boolean
}

function NotificationCountFromUserData({
  userData,
  forceFullSize,
}: Readonly<Props>) {
  const { unseenCount } = useNotifications(userData.notificationsId)
  return <NotificationCountWidget unseenCount={unseenCount} forceFullSize />
}

export default NotificationCountFromUserData
