import { useParams } from 'react-router'
import { UserDataType } from '../utils/functions/types'
import { useTranslation } from 'react-i18next'
import { getUserGroupContext } from '../utils/functions/firebase/user'
import { useStatusMessage } from '../utils/hooks/useStatusMessage'
import { useConfirmation } from '../utils/hooks/useConfirmation'
import GroupMemberCard from './group_member_card/GroupMemberCard'
import { useGroup } from './hooks/useGroup'
import { MdAdd } from 'react-icons/md'
import { usePopup } from '../utils/hooks/usePopup'
import AddMember from './popup/AddMember'
import { useGroupFunctions } from './hooks/useGroupFunctions'
import InformationPage from '../shared/information_page/InformationPage'
import InformationPageItem from '../shared/information_page/InformationPageItem'
import LoadingFullPage from '../utils/components/loading/LoadingFullPage'
import MediumButton from './modification_buttons/MediumButton'
import ModifyGroupDescription from './popup/ModifyGroupDescription'
import ModifyGroupName from './popup/ModifyGroupName'

type Props = {
  user: UserDataType
}

function Group({ user }: Readonly<Props>) {
  const params = useParams()
  const { t } = useTranslation()
  const [group, owner, members, invitedMembers, userGroupContext] = useGroup({
    groupId: params.id,
    user,
  })

  const [
    confirmation,
    confirmationContent,
    openConfirmation,
    closeConfirmation,
  ] = useConfirmation(false)
  const [popup, popupContent, openPopup, closePopup] = usePopup(false)
  const [statusMessage, openStatusMessage] = useStatusMessage({
    message: [],
    type: null,
  })
  const groupFunctions = useGroupFunctions(
    userGroupContext,
    group,
    user,
    openConfirmation,
    closePopup,
    openStatusMessage
  )
  return group ? (
    <InformationPage
      title={group?.name}
      subTitle={group?.description}
      popup={{
        isOpened: popup,
        content: popupContent,
        close: closePopup,
      }}
      confirmation={{
        isOpened: confirmation,
        content: confirmationContent,
        close: closeConfirmation,
      }}
      statusMessage={statusMessage}
    >
      {group && owner && members && invitedMembers && (
        <div className="w-full">
          <InformationPageItem title={t('members')}>
            <div className="flex w-full flex-row flex-wrap items-start justify-center gap-2">
              {members.length > 0 && (
                <div className="relative flex w-full flex-row flex-wrap items-start justify-center gap-2">
                  {members.map((member) => (
                    <GroupMemberCard
                      key={member.id}
                      targetGroupContext={getUserGroupContext(member, group)}
                      userGroupContext={getUserGroupContext(user, group)}
                      groupFunctions={groupFunctions}
                    />
                  ))}
                  {(userGroupContext.owner || userGroupContext.admin) && (
                    <button
                      className="flex h-60 w-full min-w-[15rem] max-w-[18rem] flex-1 cursor-pointer items-center justify-center rounded-lg border border-light-primary-2 bg-light-primary-0 p-4 text-light-accent-2 shadow duration-200 hover:bg-light-accent-2 hover:text-light-primary-0 dark:border-dark-primary-2 dark:bg-dark-primary-0 dark:bg-opacity-80 dark:text-dark-accent-2 dark:hover:bg-dark-accent-2 dark:hover:text-dark-secondary-0"
                      onClick={() =>
                        openPopup(
                          <AddMember
                            handleAddMember={groupFunctions.admin?.addMember}
                            user={user}
                          />
                        )
                      }
                    >
                      <div style={{ fontSize: '3rem' }}>
                        <MdAdd />
                      </div>
                    </button>
                  )}
                </div>
              )}
            </div>
          </InformationPageItem>
          {group.invitations.length > 0 && (
            <InformationPageItem title={t('invitations')}>
              <div
                className={`flex w-full flex-row flex-wrap-reverse justify-center gap-2 ${
                  members.length < 2 && 'items-center'
                }`}
              >
                {invitedMembers.map((invitation) => (
                  <GroupMemberCard
                    key={invitation.user.id}
                    targetGroupContext={getUserGroupContext(
                      invitation.user,
                      group
                    )}
                    userGroupContext={getUserGroupContext(user, group)}
                    groupFunctions={groupFunctions}
                    invitationId={invitation.invitationId}
                  />
                ))}
              </div>
            </InformationPageItem>
          )}
          {(userGroupContext.owner || userGroupContext.admin) && (
            <InformationPageItem title={t('administration')}>
              <div className="flex w-full flex-col items-center justify-center">
                <div className="flex w-full max-w-xl flex-col items-center justify-center gap-2">
                  <div className="flex w-full flex-row gap-2">
                    <MediumButton
                      onClick={() =>
                        openPopup(
                          <ModifyGroupName
                            handleModifyName={
                              groupFunctions.admin?.modifyGroupName
                            }
                          />
                        )
                      }
                    >
                      {t('modifyGroupName')}
                    </MediumButton>
                    <MediumButton
                      onClick={() =>
                        openPopup(
                          <ModifyGroupDescription
                            handleModifyDescription={
                              groupFunctions.admin?.modifyGroupDescription
                            }
                          />
                        )
                      }
                    >
                      {t('modifyGroupDescription')}
                    </MediumButton>
                  </div>
                  <button
                    className="flex w-full items-center justify-center rounded-lg border border-light-primary-3 bg-light-primary-2 p-8 text-center text-light-secondary-1 duration-200 hover:bg-light-primary-3 focus:outline-none focus:ring-4 focus:ring-light-primary-2 dark:border-dark-primary-3 dark:bg-dark-primary-2 dark:text-dark-secondary-1 dark:hover:bg-dark-primary-3 dark:focus:ring-dark-primary-2 md:p-4"
                    onClick={() => groupFunctions.member.leaveGroup(group.id)}
                  >
                    <p className="text-xl font-medium">{t('leaveGroup')}</p>
                  </button>
                  <button
                    className="flex w-full items-center justify-center rounded-lg bg-light-accent-2 p-8 text-center text-light-primary-0 duration-200 hover:bg-light-accent-1 focus:outline-none focus:ring-4 focus:ring-light-accent-1 dark:bg-dark-accent-2 dark:hover:bg-dark-accent-1 dark:focus:ring-dark-accent-1 md:p-4"
                    onClick={() => groupFunctions.admin.deleteGroup()}
                  >
                    <p className="text-xl font-medium text-light-primary-0">
                      {t('deleteGroup')}
                    </p>
                  </button>
                </div>
              </div>
            </InformationPageItem>
          )}
        </div>
      )}
    </InformationPage>
  ) : (
    <LoadingFullPage />
  )
}

export default Group
