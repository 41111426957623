import { ReactNode, useMemo } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  children: ReactNode
  to?: string
  onClick?: () => void
  sideActions?: ReactNode
  active?: boolean
  textAlign?: 'left' | 'center' | 'right'
}

function DropdownItem({
  children,
  to,
  onClick,
  sideActions,
  active,
  textAlign,
}: Readonly<Props>) {
  const itemClasses = useMemo(() => {
    return `w-full list-none truncate whitespace-nowrap rounded px-2 py-1 text-base font-normal capitalize duration-100 ${
      active
        ? 'font-semibold text-light-accent-3 dark:text-dark-accent-3'
        : 'text-light-secondary-2 dark:text-dark-secondary-2'
    } ${
      (to || onClick) &&
      'cursor-pointer hover:bg-light-primary-3 dark:hover:bg-dark-primary-3'
    } text-${textAlign || 'left'}`
  }, [active, onClick, textAlign, to])
  return (
    <li className="flex w-full select-none flex-row justify-between">
      {to ? (
        <Link className={itemClasses} onClick={onClick} to={to}>
          {children}
        </Link>
      ) : (
        <button className={itemClasses} onClick={onClick}>
          {children}
        </button>
      )}
      {sideActions && (
        <div className="flex flex-row items-center">
          <div className="m-2 h-2/3 w-px bg-light-primary-3 dark:bg-dark-primary-3"></div>
          {sideActions}
        </div>
      )}
    </li>
  )
}

export default DropdownItem
