import { Link } from 'react-router-dom'
import useWindowSize from '../hooks/useWindowSize'
import { useEffect, useMemo, useState } from 'react'
import Tooltip, { TooltipProps } from './Tooltip'

type Props = {
  icon: React.ReactNode
  to?: string
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset'
  active?: boolean
  fontSize?: string
  forceFullSize?: boolean
  forceIconClass?: string
  tooltip?: TooltipProps
}

function IconButton({
  icon,
  to,
  onClick,
  type,
  active,
  fontSize,
  forceIconClass,
  forceFullSize,
  tooltip,
}: Readonly<Props>) {
  const windowSize = useWindowSize()
  const [scaledFontSize, setScaledFontSize] = useState<string>('1.8rem')
  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onClick?.()
  }
  useEffect(() => {
    if (forceFullSize) setScaledFontSize(fontSize || '1.8rem')
    else setScaledFontSize(getFontSize(fontSize || '1.8rem'))
  }, [fontSize, forceFullSize, windowSize])
  const containerClasses = useMemo(() => {
    let containerClasses =
      'group relative flex h-full aspect-square items-center justify-center rounded-lg p-1.5 text-sm duration-200 hover:bg-light-primary-2 focus:outline-none focus:ring-2 focus:ring-light-primary-2 dark:hover:bg-dark-primary-2 dark:focus:ring-dark-primary-2'
    if (active !== undefined && !active)
      containerClasses += ' opacity-20 pointer-events-none user-select-none'
    if (tooltip) containerClasses += ' peer'
    if (!to && !onClick && tooltip) containerClasses += ' cursor-pointer'
    return containerClasses
  }, [active, onClick, to, tooltip])
  const children = (
    <div
      id="icon"
      className={
        forceIconClass ||
        'text-light-secondary-2 duration-200 group-hover:text-light-accent-2 group-hover:opacity-90 dark:text-dark-secondary-2 dark:group-hover:text-dark-accent-2'
      }
      style={{ fontSize: scaledFontSize }}
    >
      {icon}
    </div>
  )

  if (to) {
    return (
      <div className="relative">
        <Link className={containerClasses} to={to} onClick={onClick}>
          {children}
        </Link>
        {tooltip && <Tooltip {...tooltip} />}
      </div>
    )
  } else if (onClick) {
    return (
      <div className="relative">
        <button
          type={type || 'button'}
          className={containerClasses}
          onClick={onClickHandler}
        >
          {children}
        </button>
        {tooltip && <Tooltip {...tooltip} />}
      </div>
    )
  } else {
    return (
      <div className="relative">
        <div className={containerClasses}>{children}</div>
        {tooltip && <Tooltip {...tooltip} />}
      </div>
    )
  }
}

const getFontSize = (fontSize: string): string => {
  const fontSizeBase = scaleFontSize(fontSize, 0.8)
  const fontSizeXs = scaleFontSize(fontSize, 0.85)
  const fontSizeSm = scaleFontSize(fontSize, 0.9)
  let fontSizeStyle = fontSize
  if (window.innerWidth < 400) {
    fontSizeStyle = fontSizeBase
  } else if (window.innerWidth < 640) {
    fontSizeStyle = fontSizeXs
  } else if (window.innerWidth < 768) {
    fontSizeStyle = fontSizeSm
  }
  return fontSizeStyle
}

const scaleFontSize = (fontSize: string, factor: number): string => {
  // separate the number from the unit
  const number = RegExp(/(\d*[.])?\d+/).exec(fontSize)
  const scaledNumber = number ? parseFloat(number[0]) * factor : undefined
  const unit = fontSize.replace(/(\d*[.])?\d+/, '')
  return scaledNumber ? `${scaledNumber}${unit}` : fontSize
}

export default IconButton
