import { t } from 'i18next'
import { checkGroupName } from '../../utils/functions/checkValues'
import { StatusMessage } from '../../utils/functions/types'
import SimplePopupInputForm from '../../utils/components/popup/popup_content/SimplePopupInputForm'

function ModifyGroupName({
  handleModifyName,
}: Readonly<{
  handleModifyName: (name: string) => void
}>) {
  const handleSubmit = (
    inputs: { [input: string]: string },
    openStatusMessage: (status: StatusMessage) => void
  ) => {
    let status = checkGroupName(inputs['name'])
    if (status.type === 'error') {
      openStatusMessage(status)
    } else {
      handleModifyName(inputs['name'])
    }
  }
  return (
    <SimplePopupInputForm
      inputs={{ name: 'text' }}
      onSubmit={handleSubmit}
      title={t('modifyGroupName')}
      placeholder={t('name')}
      submitText={t('modify')}
    />
  )
}

export default ModifyGroupName
