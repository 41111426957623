import { User } from 'firebase/auth'
import {
  DocumentData,
  QueryDocumentSnapshot,
  doc,
  getDoc,
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { GroupDataType, UserDataType } from '../../utils/functions/types'
import { db } from '../../utils/functions/firebase/auth'

export const useUserData = (
  user: User
): [
  userData: UserDataType | undefined,
  groupsData: GroupDataType[] | undefined,
] => {
  const [userInfo] = useDocument(doc(db, 'users', user.uid))
  const [userData, setUserData] = useState<UserDataType>()
  const [groupsData, setGroupsData] = useState<GroupDataType[]>()
  const mapGroupData = (
    group: QueryDocumentSnapshot<DocumentData>
  ): GroupDataType => {
    let g = group.data() as GroupDataType
    g.id = group.id
    return g
  }
  useEffect(() => {
    const fetchGroups = async () => {
      const groups = userInfo.data().groups
      const groupsData: GroupDataType[] = []
      for (const group of groups) {
        const groupData = await getDoc(doc(db, 'groups', group))
        groupsData.push(mapGroupData(groupData))
      }
      setGroupsData(groupsData)
    }
    if (userInfo?.exists()) {
      fetchGroups()
      let u = userInfo.data() as UserDataType
      u.id = userInfo.id
      setUserData(u)
    }
  }, [userInfo])
  return [userData, groupsData]
}
