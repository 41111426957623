import { useTranslation } from 'react-i18next'
import LoadingFullPage from './utils/components/loading/LoadingFullPage'
import { useLocalStorage } from '@uidotdev/usehooks'
import { Navigate } from 'react-router'

function AccountDeletionPage() {
  const [accountDeletion] = useLocalStorage('accountDeletion')
  const { t } = useTranslation()
  return accountDeletion ? (
    <div>
      <LoadingFullPage text={t('deletingYourAccount') + '...'} />
    </div>
  ) : (
    <Navigate to={'/'} replace={true} />
  )
}

export default AccountDeletionPage
