import { useLayoutEffect, useState } from 'react'

export default function useWindowIsScrolled() {
  const [windowIsScrolled, setWindowIsScrolled] = useState(false)
  useLayoutEffect(() => {
    function updatePosition() {
      setWindowIsScrolled(window.scrollY / window.screen.height > 0.2)
    }
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])
  return windowIsScrolled
}
