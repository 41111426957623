import { useCallback } from 'react'
import { usePopup } from './usePopup'
import ConfirmationContent from '../components/confirmation/ConfirmationContent'

export const useConfirmation = (
  isInitiallyOpen: boolean
): [
  isOpen: boolean,
  content: React.ReactNode,
  openConfirmation: (question: string, onConfirm: () => Promise<void>) => void,
  close: () => void,
] => {
  const [isOpen, content, open, close] = usePopup(isInitiallyOpen)
  const handleOpen = useCallback(
    (question: string, onAccept: () => Promise<void>) => {
      open(
        <ConfirmationContent
          text={question}
          close={close}
          onAccept={onAccept}
        />
      )
    },
    [open, close]
  )
  return [isOpen, content, handleOpen, close]
}
