import { isValidPhoneNumber } from 'react-phone-number-input'
import { CountryCode, StatusMessage } from './types'
import { emailExists, phoneExists } from './firebase/auth'

export const checkSignEmailPassword = async (
  email: string,
  password: string
): Promise<StatusMessage> => {
  let check = checkEmailPassword(email, password)
  if (check.type === 'error') {
    return check
  } else {
    let used = await emailExists(email)
    if (used) return { message: ['email-exists'], type: 'error' }
    return { message: ['signin-success'], type: 'success' }
  }
}

export const checkEmail = (email: string): StatusMessage => {
  let message = []
  if (email.length === 0) {
    message.push('email-empty')
  } else if (
    !RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    ).exec(email)
  )
    message.push('email-wrong')

  return { message: message, type: message.length > 0 ? 'error' : 'success' }
}

const checkPassword = (password: string): StatusMessage => {
  let message = []
  if (password.length === 0) {
    message.push('pass-empty')
  } else if (password.length < 7) {
    message.push('pass-too-short')
  }
  return { message: message, type: message.length > 0 ? 'error' : 'success' }
}

export const checkEmailPassword = (
  email: string,
  password: string
): StatusMessage => {
  let emailCheck = checkEmail(email)
  let passwordCheck = checkPassword(password)
  if (emailCheck.type === 'error' || passwordCheck.type === 'error') {
    return {
      message: [...emailCheck.message, ...passwordCheck.message],
      type: 'error',
    }
  }
  return { message: [], type: 'success' }
}

export const checkNamesPhone = async (
  firstName: string,
  name: string,
  phone: string,
  country: CountryCode
): Promise<StatusMessage> => {
  let message = []
  if (firstName.length === 0) message.push('firstname-empty')
  if (name.length === 0) message.push('name-empty')
  if (phone.length === 0) message.push('phone-empty')
  if (!isValidPhoneNumber(phone, country)) message.push('phone-wrong')
  if (message.length === 0) {
    let used = await phoneExists(phone)
    if (used) {
      message.push('phone-exists')
    } else {
      return { message: [], type: 'success' }
    }
  }
  return { message: message, type: 'error' }
}

export const checkGroupInput = (
  name: string,
  description: string,
  giftsPerUser: string
): StatusMessage => {
  let message: string[] = []
  let checkName = checkGroupName(name)
  let checkDescription = checkGroupDescription(description)
  let checkGifts = checkGroupGifts(giftsPerUser)
  if (
    checkName.type === 'error' ||
    checkDescription.type === 'error' ||
    checkGifts.type === 'error'
  ) {
    return {
      message: [
        ...checkName.message,
        ...checkDescription.message,
        ...checkGifts.message,
      ],
      type: 'error',
    }
  }
  return { message: message, type: 'success' }
}

export const checkGroupName = (name: string): StatusMessage => {
  let message: string[] = []
  if (name.length === 0) message.push('group-name-empty')
  return { message: message, type: message.length > 0 ? 'error' : 'success' }
}

export const checkGroupDescription = (description: string): StatusMessage => {
  let message: string[] = []
  if (description.length === 0) message.push('group-description-empty')
  return { message: message, type: message.length > 0 ? 'error' : 'success' }
}

export const checkGroupGifts = (giftsPerUser: string): StatusMessage => {
  let message: string[] = []
  if (giftsPerUser.length === 0) message.push('group-gifts-empty')
  return { message: message, type: message.length > 0 ? 'error' : 'success' }
}
