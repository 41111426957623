import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import Status from '../../utils/components/Status'
import Confirmation from '../../utils/components/confirmation/Confirmation'
import { StatusMessage } from '../../utils/functions/types'
import Popup from '../../utils/components/popup/Popup'

type Props = {
  children: React.ReactNode
  title: string
  subTitle: string
  popup?: {
    isOpened: boolean
    content: React.ReactNode
    close: () => void
  }
  confirmation?: {
    isOpened: boolean
    content: React.ReactNode
    close: () => void
  }
  statusMessage: StatusMessage
}
function InformationPage({
  children,
  title,
  subTitle,
  popup,
  confirmation,
  statusMessage,
}: Readonly<Props>) {
  const handleNavigateBack = () => {
    window.history.back()
  }
  return (
    <div className="relative mx-auto flex flex-col items-center justify-center gap-2 px-2 pb-8 pt-4 sm:gap-4 sm:p-10 xl:px-0">
      {confirmation && (
        <Confirmation
          isOpened={confirmation.isOpened}
          close={confirmation.close}
        >
          {confirmation.content}
        </Confirmation>
      )}
      {popup && (
        <Popup isOpened={popup.isOpened} close={popup.close}>
          {popup.content}
        </Popup>
      )}
      <button
        className="absolute left-0 top-2 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-light-primary-3 bg-light-primary-1 pr-px text-xl text-light-secondary-0 shadow duration-200 hover:border-light-accent-4 hover:bg-light-accent-2 hover:text-light-primary-1 dark:border-dark-primary-3 dark:bg-dark-primary-1 dark:text-dark-secondary-0 dark:hover:border-dark-accent-4 dark:hover:bg-dark-accent-2 md:left-4 md:top-4 md:h-12 md:w-12 md:text-2xl"
        onClick={handleNavigateBack}
      >
        <MdOutlineArrowBackIosNew />
      </button>
      <div className="border-b-2 border-light-secondary-2 px-8 py-4 text-center dark:border-dark-secondary-2 md:min-w-[400px]">
        <p className="line-clamp-2 text-3xl font-semibold uppercase tracking-tight text-light-secondary-1 duration-200 dark:text-dark-secondary-2 xs:text-4xl sm:text-5xl lg:text-7xl">
          {title}
        </p>
        <p className="line-clamp-2 text-base tracking-tight text-light-secondary-2 dark:text-dark-secondary-2 sm:text-lg lg:text-2xl">
          {subTitle}
        </p>
      </div>
      <Status statusMessage={statusMessage} />
      <div className="flex w-full flex-col items-center justify-center gap-8">
        <div className="flex w-full flex-col items-start justify-center gap-4">
          {children}
        </div>
      </div>
    </div>
  )
}

export default InformationPage
