import { t } from 'i18next'
import { StatusMessage } from '../../utils/functions/types'

function Status({ statusMessage }: Readonly<{ statusMessage: StatusMessage }>) {
  return (
    <div className="min-h-6 w-full">
      {statusMessage.type && (
        <div
          className={`flex flex-col gap-0 ${
            statusMessage.type === 'success' ? 'bg-emerald-600' : 'bg-red-600'
          }`}
        >
          {statusMessage.message.map((message) => (
            <p
              className="w-full rounded-sm px-4 text-center text-base text-white"
              key={`statusMessage-${message}`}
            >
              {t(message)}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

export default Status
