import { Link } from 'react-router-dom'
import { GroupDataType, UserDataType } from '../../utils/functions/types'
import GroupTabMembersItem from './GroupTabMembersItem'
import { useTranslation } from 'react-i18next'

type Props = {
  group: GroupDataType
  owner: UserDataType
  members: UserDataType[]
}

function GroupTabMembers({ group, owner, members }: Readonly<Props>) {
  const { t } = useTranslation()
  return (
    <div
      className="flex h-full w-full flex-col gap-2 rounded-lg px-8 py-4"
      id="about"
      role="tabpanel"
      aria-labelledby="about-tab"
    >
      <h2 className="text-light-seconadry-1 text-xl font-semibold tracking-tight dark:text-dark-secondary-1">
        {group.name}
      </h2>
      <div className="grid h-min grid-cols-2 grid-rows-2 gap-4 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-3">
        {members.slice(0, 5).map((member) => {
          return (
            <GroupTabMembersItem
              key={member.id}
              member={member}
              owner={owner && owner.id === member.id}
            />
          )
        })}
        {group.members.length > 5 && (
          <Link
            to={`/group/${group.id}`}
            className="hover:border-light-accent-7 dark:hover:border-dark-accent-7 flex cursor-pointer flex-col items-center justify-center rounded-md border-2 border-light-primary-3 bg-light-primary-1 duration-200 hover:bg-light-accent-5 hover:text-light-accent-2 dark:border-dark-primary-3 dark:bg-dark-primary-1 dark:hover:bg-dark-accent-5 dark:hover:text-dark-accent-2"
          >
            <p className="line-clamp-2 text-center text-lg leading-none">
              {'+'}
              {group.members.length - 6} {t('others')}
            </p>
          </Link>
        )}
      </div>
    </div>
  )
}

export default GroupTabMembers
