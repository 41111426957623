import { doc, getDoc } from 'firebase/firestore'
import { GroupDataType } from '../types'
import { db } from './auth'

export const getGroup = async (groupId: string): Promise<GroupDataType> => {
  try {
    const groupDoc = await getDoc(doc(db, 'groups', groupId))
    if (groupDoc.exists()) {
      return getGroupDataFromDoc(groupDoc)
    } else {
      return null
    }
  } catch (error: any) {
    console.log('error while getting group : ' + error.message)
    return null
  }
}

export const getGroupDataFromDoc = (groupDoc: any): GroupDataType => {
  const groupData = groupDoc.data() as GroupDataType
  groupData.id = groupDoc.id
  return groupData
}
