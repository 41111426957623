import { IoNotifications } from 'react-icons/io5'
import { UserDataType } from '../../../utils/functions/types'
import useComponentVisible from '../../../utils/hooks/useComponentVisible'
import { useCallback } from 'react'
import Dropdown from '../../../utils/components/dropdown/Dropdown'
import DropdownGroup from '../../../utils/components/dropdown/DropdownGroup'
import DropdownItem from '../../../utils/components/dropdown/DropdownItem'
import Loading from '../../../utils/components/loading/LoadingWidget'
import { t } from 'i18next'
import InvitationActions from './side_actions/InvitationActions'
import { markEventsAsSeen } from '../../../utils/functions/firebase/notifications'
import EventActions from './side_actions/EventActions'
import { useNotifications } from './hooks/useNotifications'
import NotificationCount from '../../notification_count/NotificationCount'
import IconButton from '../../../utils/components/IconButton'

type Props = {
  userData: UserDataType
  iconFontSize?: string
  xOffSet?: string
  yOffSet?: string
}

function Notifications({ userData, iconFontSize, xOffSet, yOffSet }: Props) {
  const [ref, dropdown, setDropdown] = useComponentVisible(false)
  const { notifications, unseenCount, notificationMessages } = useNotifications(
    userData.notificationsId
  )

  const dropdownClickHandler = useCallback(() => {
    if (dropdown) {
      setDropdown(false)
    } else {
      setDropdown(true)
      markEventsAsSeen(userData.id)
    }
  }, [dropdown, setDropdown, userData.id])

  const noNotifications = () => {
    return (
      notifications.invitations.length === 0 &&
      notifications.events.length === 0
    )
  }

  return userData && notifications ? (
    <div ref={ref} className="relative">
      <IconButton
        icon={
          <div>
            <IoNotifications />
            <NotificationCount data={unseenCount} />
          </div>
        }
        onClick={dropdownClickHandler}
        fontSize={iconFontSize || '1.8rem'}
      />
      {dropdown && (
        <Dropdown
          containerRef={ref}
          xOffSet={xOffSet || '-32%'}
          yOffSet={yOffSet || '6px'}
        >
          {noNotifications() && (
            <DropdownGroup>
              <DropdownItem>
                <p className="whitespace-pre-wrap break-words text-base normal-case">
                  {t('noNotifications')}
                </p>
              </DropdownItem>
            </DropdownGroup>
          )}
          {notifications.invitations.length !== 0 && (
            <DropdownGroup name={t('invitations')}>
              {notifications.invitations.map((invitation) => (
                <DropdownItem
                  key={invitation.id}
                  sideActions={
                    <InvitationActions
                      invitation={invitation}
                      closeDropdown={() => setDropdown(false)}
                    />
                  }
                  to="/settings"
                >
                  <div className="flex flex-row items-center gap-2">
                    <div className="mt-px">
                      <p className="whitespace-pre-wrap break-words text-left text-sm normal-case md:text-base">
                        {notificationMessages.invitations[invitation.id] ? (
                          notificationMessages.invitations[invitation.id]
                        ) : (
                          <Loading size={'5'} />
                        )}
                      </p>
                    </div>
                  </div>
                </DropdownItem>
              ))}
            </DropdownGroup>
          )}
          {notifications.events.length !== 0 && (
            <DropdownGroup>
              {notifications.events.map((event) => (
                <DropdownItem
                  key={event.id}
                  to="/settings"
                  sideActions={<EventActions event={event} />}
                >
                  <p className="mb-2 whitespace-pre-wrap text-left text-sm normal-case">
                    {notificationMessages.events[event.id] ? (
                      notificationMessages.events[event.id]
                    ) : (
                      <Loading size={'5'} />
                    )}
                  </p>
                </DropdownItem>
              ))}
            </DropdownGroup>
          )}
        </Dropdown>
      )}
    </div>
  ) : (
    <Loading size={'7'} />
  )
}

export default Notifications
