import { doc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import {
  NotificationMessages,
  NotificationsType,
} from '../../../../utils/functions/types'
import {
  getEventMessage,
  getInvitationMessage,
} from '../../../../utils/functions/firebase/notifications'
import { useTranslation } from 'react-i18next'
import { db } from '../../../../utils/functions/firebase/auth'

type PropsReturn = {
  notifications: NotificationsType
  unseenCount: number
  notificationMessages: NotificationMessages
}

export const useNotifications = (notificationsId: string): PropsReturn => {
  const [notificationData] = useDocument(
    doc(db, 'notifications', notificationsId)
  )
  const [notifications, setNotifications] = useState<NotificationsType>({
    invitations: [],
    events: [],
  } as NotificationsType)
  const [unseenCount, setUnseenCount] = useState<number>(0)
  const [notificationMessages, setNotificationMessages] =
    useState<NotificationMessages>({ events: {}, invitations: {} })
  const { t } = useTranslation()
  useEffect(() => {
    if (notificationData) {
      let n = notificationData.data() as NotificationsType
      setNotifications(n)
      let c = 0
      for (const invitation of n.invitations) {
        getInvitationMessage(invitation).then((message) => {
          setNotificationMessages((prevState) => {
            const invitations = prevState.invitations
            invitations[invitation.id] = message
            return {
              ...prevState,
              invitations: invitations,
            }
          })
        })
        c++
      }
      for (const event of n.events) {
        getEventMessage(event).then((message) => {
          setNotificationMessages((prevState) => {
            const events = prevState.events
            events[event.id] = message
            return {
              ...prevState,
              events: events,
            }
          })
        })
        if (!event.seen) c++
      }
      setUnseenCount(c)
    }
  }, [notificationData, t])
  return { notifications, unseenCount, notificationMessages }
}
