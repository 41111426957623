import { MdOutlineCheck, MdOutlineClear } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import {
  acceptInvitation,
  declineInvitation,
} from '../../../../utils/functions/firebase/notifications'
import { InvitationType } from '../../../../utils/functions/types'
import IconButton from '../../../../utils/components/IconButton'

type Props = {
  invitation: InvitationType
  closeDropdown: () => void
}

function InvitationActions({ invitation, closeDropdown }: Readonly<Props>) {
  const { t } = useTranslation()
  return (
    <div className="flex w-24 flex-row items-center justify-around text-2xl md:text-3xl">
      <IconButton
        icon={<MdOutlineCheck />}
        onClick={() => {
          acceptInvitation(
            invitation.group,
            invitation.to,
            invitation.id,
            (statusMessage) => {
              console.log(statusMessage)
            }
          )
          closeDropdown()
        }}
        fontSize="2rem"
        forceIconClass="text-light-green-1 dark:text-dark-green-1"
        tooltip={{
          text: t('accept'),
          distance: '5%',
          bottom: true,
        }}
      />
      <IconButton
        icon={<MdOutlineClear />}
        onClick={() => {
          declineInvitation(
            invitation.group,
            invitation.to,
            invitation.id,
            (statusMessage) => {
              console.log(statusMessage)
            }
          )
          closeDropdown()
        }}
        fontSize="2rem"
        forceIconClass="text-light-accent-1 dark:text-dark-accent-1"
        tooltip={{
          text: t('decline'),
          distance: '5%',
          bottom: true,
        }}
      />
    </div>
  )
}

export default InvitationActions
