import { useTranslation } from 'react-i18next'
import Input from '../utils/components/Input'
import { checkGroupInput } from '../utils/functions/checkValues'
import { useStatusMessage } from '../utils/hooks/useStatusMessage'
import { StatusMessage } from '../utils/functions/types'
import Status from '../utils/components/Status'
import { useState, FormEvent } from 'react'
import { createGroup } from '../utils/functions/firebase/groupManagement'
import { useNavigate } from 'react-router'

type Props = {
  handleCreateGroup?: (
    name: string,
    description: string,
    giftPerUser: string
  ) => void
}

function NewGroup({ handleCreateGroup }: Readonly<Props>) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [giftPerUser, setGiftPerUser] = useState('')
  const [statusMessage, openStatusMessage] = useStatusMessage({
    message: [''],
    type: null,
  })
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const status: StatusMessage = checkGroupInput(
      name,
      description,
      giftPerUser
    )
    if (status.type === 'error') {
      openStatusMessage(status)
    } else if (handleCreateGroup)
      handleCreateGroup(name, description, giftPerUser)
    else {
      let groupId = await createGroup(
        name,
        description,
        giftPerUser,
        openStatusMessage
      )
      navigate(`/group/${groupId}`)
    }
  }
  return (
    <div className="mx-auto flex flex-col items-center  justify-center gap-6 sm:w-[400px]">
      <Status statusMessage={statusMessage} />
      <p className="font-cookie text-5xl text-light-secondary-0 dark:text-dark-secondary-0 md:text-6xl">
        {t('createGroup')}
      </p>
      <form
        onSubmit={handleSubmit}
        className="flex w-full flex-col items-center justify-center gap-4"
      >
        <Input
          type="text"
          placeholder={t('name')}
          onChange={setName}
          value={name}
        />
        <Input
          type="text"
          placeholder={t('description')}
          onChange={setDescription}
          value={description}
        />
        <Input
          type="text"
          placeholder={t('giftPerUser')}
          onChange={setGiftPerUser}
          value={giftPerUser}
        />
        <button
          type="submit"
          className="font-md w-full rounded bg-light-accent-2 py-3 text-light-primary-0 duration-200 hover:bg-light-accent-3 dark:bg-dark-accent-2 dark:text-light-primary-0 dark:hover:bg-dark-accent-3"
        >
          {t('createGroup')}
        </button>
      </form>
    </div>
  )
}

export default NewGroup
