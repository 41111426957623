import { db, auth } from './utils/functions/firebase/auth'
import { Routes, Route } from 'react-router'
import Authentification from './auth/Authentification'
import Main from './main/Main'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Navigate } from 'react-router-dom'
import { useLocalStorage } from '@uidotdev/usehooks'
import { useCallback } from 'react'
import useWindowIsScrolled from './utils/hooks/useWindowPosition'
import { HiOutlineChevronUp } from 'react-icons/hi'
import AccountDeletionPage from './AccountDeletionPage'

function App() {
  const [user] = useAuthState(auth)
  const [darkMode, setDarkMode] = useLocalStorage(
    'darkMode',
    window.matchMedia('(prefers-color-scheme: dark)').matches
  )
  const toggleDarkMode = useCallback(() => {
    setDarkMode(!darkMode)
  }, [darkMode, setDarkMode])
  const windowIsScrolled = useWindowIsScrolled()
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return auth && db ? (
    <div
      className={`${
        darkMode ? 'dark ' : ''
      }relative inset-0 flex min-h-screen flex-col ${
        darkMode ? 'bg-dark-primary-1' : 'bg-light-primary-1'
      } duration-200`}
    >
      <button
        className={`fixed -top-10 left-1/2 z-50 flex h-10 w-10 -translate-x-1/2 cursor-pointer items-center justify-center rounded-full bg-light-primary-3 text-3xl text-light-secondary-1 opacity-90 duration-200 hover:scale-110 dark:bg-dark-primary-3 dark:text-dark-secondary-1 ${
          !windowIsScrolled ? ' invisible' : 'translate-y-16'
        }`}
        onClick={scrollToTop}
      >
        <HiOutlineChevronUp />
      </button>

      <Routes>
        <Route
          path="*"
          element={
            user ? (
              <Main
                user={user}
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
              />
            ) : (
              <Navigate to={'/auth/login'} />
            )
          }
        />
        <Route
          path="/auth/*"
          element={
            !user ? (
              <Authentification auth={auth} toggleDarkMode={toggleDarkMode} />
            ) : (
              <Navigate to={'/'} />
            )
          }
        />
        <Route path="/account-deletion" element={<AccountDeletionPage />} />
      </Routes>
    </div>
  ) : null
}

export default App
