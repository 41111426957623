import { useRef } from 'react'

function DropdownCountries({ children }: Readonly<{ children: any }>) {
  const dropdownRef = useRef(null)
  return (
    <div className="h-min w-min">
      <ul
        className="absolute mt-1 max-h-52 w-full rounded-sm bg-light-primary-2 px-2 py-4 shadow-xl dark:bg-dark-primary-2"
        style={{
          transform: 'translate(calc(-100% + 5px), 28px)',
          width: 'calc(100% + 2px)',
        }}
        ref={dropdownRef}
      >
        <div className="flex max-h-44 flex-col justify-start gap-1 overflow-y-auto pb-2 scrollbar scrollbar-track-light-primary-3 scrollbar-thumb-light-primary-4 scrollbar-thumb-rounded-sm dark:scrollbar-track-dark-primary-3 dark:scrollbar-thumb-dark-primary-4">
          {children}
        </div>
      </ul>
      <div className="absolute right-[59px] h-4 w-4 translate-y-6 rotate-45 bg-light-primary-2 dark:bg-dark-primary-2"></div>
    </div>
  )
}

export default DropdownCountries
