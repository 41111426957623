import { LegacyRef, forwardRef } from 'react'
import { CountryCode } from '../../utils/functions/types'
import PhoneInputWithoutCountrySelect from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import fr from 'react-phone-number-input/locale/fr.json'
import i18n from '../../utils/functions/translation'
import DropdownCountries from './DropdownCountries'
import DropdownCountriesItem from './DropdownCountriesItem'
import { MdOutlineArrowDropDown } from 'react-icons/md'
import { DefaultInputComponentProps } from 'react-phone-number-input'
import useComponentVisible from '../../utils/hooks/useComponentVisible'

const Input = forwardRef(
  (
    { value, onChange, placeholder }: DefaultInputComponentProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const onFocus = () => {
      const input = document.getElementById('phoneInput')
      if (input) {
        input.classList.add(
          'border-light-accent-4',
          'dark:border-dark-accent-4'
        )
      }
    }
    const onBlur = () => {
      const input = document.getElementById('phoneInput')
      if (input) {
        input.classList.remove(
          'border-light-accent-4',
          'dark:border-dark-accent-4'
        )
      }
    }

    return (
      <div className="relative w-full">
        <input
          ref={ref}
          type="text"
          placeholder={placeholder}
          className="peer h-12 w-full rounded-sm bg-transparent px-4 text-light-secondary-0 outline-none duration-200 placeholder:opacity-0 focus:border-light-accent-3 dark:text-dark-secondary-0 dark:focus:border-dark-accent-3"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <div
          style={{ lineHeight: '1.1' }}
          className={`pointer-events-none absolute -translate-y-1/2 bg-light-primary-1 px-1 text-light-secondary-4 duration-200 first-letter:uppercase peer-focus:text-light-accent-2 dark:bg-dark-primary-1 dark:text-dark-secondary-4 dark:peer-focus:text-dark-accent-2 ${
            value.length > 0
              ? 'left-2 top-0 text-sm'
              : 'left-4 top-1/2 peer-focus:left-2 peer-focus:top-0 peer-focus:text-sm'
          }
        `}
        >
          {placeholder}
        </div>
      </div>
    )
  }
)

const getLabel = (code: CountryCode): string => {
  switch (i18n.language) {
    case 'fr':
      return fr[code]
    case 'en':
      return en[code]
    default:
      return fr[code]
  }
}

function CountrySelect({
  value,
  onClick,
}: Readonly<{ value: string; onClick: any }>) {
  const [ref, dropdown, setDropdown] = useComponentVisible(false)
  const toggleDropdown = () => {
    setDropdown(!dropdown)
  }
  return (
    <button
      ref={ref}
      className="flex h-full cursor-pointer flex-row items-center justify-end pl-4 pr-1"
      onClick={toggleDropdown}
    >
      <div className="text-3xl text-light-secondary-2 dark:text-dark-secondary-2">
        <MdOutlineArrowDropDown />
      </div>
      <img
        crossOrigin="anonymous"
        src={'https://flagsapi.com/' + value.toUpperCase() + '/flat/64.png'}
        alt="Country flag used for number format"
        className="h-12 w-12"
      />
      {dropdown && (
        <DropdownCountries>
          {getCountries()
            .sort((a, b) => {
              let labelA = getLabel(a)
              let labelB = getLabel(b)
              if (labelA > labelB) return 1
              if (labelA < labelB) return -1
              return 0
            })
            .map((country) => {
              return (
                <DropdownCountriesItem
                  key={country}
                  id={country}
                  selected={value === country}
                  text={getLabel(country)}
                  onClick={onClick}
                />
              )
            })}
        </DropdownCountries>
      )}
    </button>
  )
}

type Props = {
  value: string
  onChange: any
  placeholder: string
  country: CountryCode
  setCountry: any
}

function PhoneInput({
  value,
  onChange,
  placeholder,
  country,
  setCountry,
}: Readonly<Props>) {
  return (
    <div
      id="phoneInput"
      className="relative flex w-full flex-row rounded-sm border border-light-secondary-4 bg-transparent outline-none duration-200 placeholder:opacity-0 dark:border-dark-secondary-4"
    >
      <PhoneInputWithoutCountrySelect
        country={country}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        inputComponent={Input}
      />
      <CountrySelect value={country} onClick={setCountry} />
    </div>
  )
}

export default PhoneInput

const getCountries = (): CountryCode[] => {
  return [
    'AD',
    'AE',
    'AF',
    'AG',
    'AI',
    'AL',
    'AM',
    'AO',
    'AR',
    'AS',
    'AT',
    'AU',
    'AW',
    'AX',
    'AZ',
    'BA',
    'BB',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BI',
    'BJ',
    'BL',
    'BM',
    'BN',
    'BO',
    'BQ',
    'BR',
    'BS',
    'BT',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CC',
    'CD',
    'CF',
    'CG',
    'CH',
    'CI',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CU',
    'CV',
    'CW',
    'CX',
    'CY',
    'CZ',
    'DE',
    'DJ',
    'DK',
    'DM',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EG',
    'EH',
    'ER',
    'ES',
    'ET',
    'FI',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'GA',
    'GB',
    'GD',
    'GE',
    'GF',
    'GG',
    'GH',
    'GI',
    'GL',
    'GM',
    'GN',
    'GP',
    'GQ',
    'GR',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HN',
    'HR',
    'HT',
    'HU',
    'ID',
    'IE',
    'IL',
    'IM',
    'IN',
    'IO',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JE',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LK',
    'LR',
    'LS',
    'LT',
    'LU',
    'LV',
    'LY',
    'MA',
    'MC',
    'MD',
    'ME',
    'MF',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MQ',
    'MR',
    'MS',
    'MT',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NF',
    'NG',
    'NI',
    'NL',
    'NO',
    'NP',
    'NR',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PG',
    'PH',
    'PK',
    'PL',
    'PM',
    'PR',
    'PS',
    'PT',
    'PW',
    'PY',
    'QA',
    'RE',
    'RO',
    'RS',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SG',
    'SH',
    'SI',
    'SJ',
    'SK',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'SS',
    'ST',
    'SV',
    'SX',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TG',
    'TH',
    'TJ',
    'TK',
    'TL',
    'TM',
    'TN',
    'TO',
    'TR',
    'TT',
    'TV',
    'TW',
    'TZ',
    'UA',
    'UG',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WF',
    'WS',
    'YE',
    'YT',
    'ZA',
    'ZM',
    'ZW',
  ]
}
