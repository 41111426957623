import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { UserDataType } from '../utils/functions/types'
import Banner from '../utils/components/Banner'
import { Link } from 'react-router-dom'

type Props = {
  userData: UserDataType
}

function Home({ userData }: Props) {
  const { t } = useTranslation()
  const isProfileComplete = useCallback(() => {
    if (userData) {
      if (
        userData.name &&
        userData.firstName &&
        userData.email &&
        userData.phone
      ) {
        return true
      }
    }
    return false
  }, [userData])
  return (
    userData && (
      <div>
        {!isProfileComplete() && (
          <Banner>
            <div className="flex flex-row items-center justify-center">
              <p>
                {t('profileIncomplete')}{' '}
                <Link
                  to="/profile"
                  className="text-light-accent-2 duration-200 hover:text-light-accent-3 dark:text-dark-accent-2 dark:hover:text-dark-accent-3"
                >
                  {t('here')}
                </Link>
              </p>
            </div>
          </Banner>
        )}
      </div>
    )
  )
}

export default Home
