import { useCallback, useEffect, useState } from 'react'
import {
  AdminFunctions,
  GroupDataType,
  MemberFunctions,
  OwnerFunctions,
  StatusMessage,
  UserDataType,
  GroupFunctions,
  UserGroupContext,
} from '../../utils/functions/types'
import {
  leaveGroup,
  sendInvitation,
  kickMember,
  promoteToAdmin,
  giveOwnership,
  modifyGroupDescription,
  modifyGroupName,
  deleteGroup,
  demoteAdmin,
} from '../../utils/functions/firebase/groupManagement'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import {
  declineInvitation,
  cancelInvitation,
} from '../../utils/functions/firebase/notifications'

export const useGroupFunctions = (
  userGroupContext: UserGroupContext,
  groupData: GroupDataType,
  userData: UserDataType,
  openConfirmation: (question: string, action: () => Promise<void>) => void,
  closePopup: () => void,
  openStatusMessage: (statusMessage: StatusMessage) => void
): GroupFunctions => {
  const [groupFunctions, setGroupFunctions] = useState<GroupFunctions>()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const leaveGroupHandler = useCallback(() => {
    openConfirmation(t('leaveGroup?'), async () => {
      await leaveGroup(groupData, openStatusMessage)
      if (location.pathname.includes('/group')) {
        navigate('/groups')
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    })
  }, [
    groupData,
    openConfirmation,
    t,
    openStatusMessage,
    location.pathname,
    navigate,
  ])
  const addMemberHandler = useCallback(
    (email: string) => {
      closePopup()
      sendInvitation(groupData?.id, userData, email, openStatusMessage)
    },
    [closePopup, groupData?.id, userData, openStatusMessage]
  )
  const removeMemberHandler = useCallback(
    (memberId: string) => {
      openConfirmation(t('removeMember?'), () =>
        kickMember(groupData?.id, memberId, openStatusMessage)
      )
    },
    [groupData?.id, openConfirmation, t, openStatusMessage]
  )
  const declineInvitationHandler = useCallback(
    (userId: string, notificationId: string) => {
      openConfirmation(t('deleteInvitation?'), () =>
        declineInvitation(
          groupData?.id,
          userId,
          notificationId,
          openStatusMessage
        )
      )
    },
    [groupData?.id, openConfirmation, t, openStatusMessage]
  )
  const cancelInvitationHandler = useCallback(
    (userId: string, notificationId: string) => {
      openConfirmation(t('deleteInvitation?'), () =>
        cancelInvitation(
          groupData?.id,
          userId,
          notificationId,
          openStatusMessage
        )
      )
    },
    [groupData?.id, openConfirmation, t, openStatusMessage]
  )
  const modifyGroupNameHandler = useCallback(
    (name: string) => {
      closePopup()
      window.scrollTo({ top: 0, behavior: 'smooth' })
      modifyGroupName(groupData?.id, name, openStatusMessage)
    },
    [closePopup, groupData?.id, openStatusMessage]
  )
  const modifyGroupDescriptionHandler = useCallback(
    (description: string) => {
      closePopup()
      window.scrollTo({ top: 0, behavior: 'smooth' })
      modifyGroupDescription(groupData?.id, description, openStatusMessage)
    },
    [closePopup, groupData?.id, openStatusMessage]
  )
  const deleteGroupHandler = useCallback(async () => {
    openConfirmation(t('deleteGroup?'), async () => {
      await deleteGroup(groupData?.id, openStatusMessage)
      if (location.pathname.includes('/group')) {
        navigate('/groups')
      }
    })
  }, [
    groupData?.id,
    openConfirmation,
    t,
    openStatusMessage,
    location.pathname,
    navigate,
  ])
  const promoteToAdminHandler = useCallback(
    (id: string) => {
      promoteToAdmin(groupData?.id, id, openStatusMessage)
    },
    [groupData?.id, openStatusMessage]
  )
  const demoteAdminHandler = useCallback(
    (id: string) => {
      demoteAdmin(groupData?.id, id, openStatusMessage)
    },
    [groupData?.id, openStatusMessage]
  )
  const giveOwnershipHandler = useCallback(
    (id: string) => {
      openConfirmation(t('giveOwnership?'), () =>
        giveOwnership(groupData?.id, id, openStatusMessage)
      )
    },
    [groupData?.id, openConfirmation, t, openStatusMessage]
  )

  useEffect(() => {
    if (userGroupContext && groupData) {
      const memberFunctions: MemberFunctions = {
        leaveGroup: leaveGroupHandler,
      }
      const adminFunctions: AdminFunctions = {
        addMember: addMemberHandler,
        removeMember: removeMemberHandler,
        cancelInvitation: cancelInvitationHandler,
        modifyGroupName: modifyGroupNameHandler,
        modifyGroupDescription: modifyGroupDescriptionHandler,
        deleteGroup: deleteGroupHandler,
      }
      const ownerFunctions: OwnerFunctions = {
        promoteToAdmin: promoteToAdminHandler,
        demoteAdmin: demoteAdminHandler,
        giveOwnership: giveOwnershipHandler,
      }

      setGroupFunctions({
        member: userGroupContext.member ? memberFunctions : null,
        admin: userGroupContext.admin ? adminFunctions : null,
        owner: userGroupContext.owner ? ownerFunctions : null,
      })
    }
  }, [
    userGroupContext,
    groupData,
    openConfirmation,
    closePopup,
    openStatusMessage,
    t,
    location,
    navigate,
    userData,
    leaveGroupHandler,
    addMemberHandler,
    removeMemberHandler,
    declineInvitationHandler,
    modifyGroupNameHandler,
    modifyGroupDescriptionHandler,
    deleteGroupHandler,
    promoteToAdminHandler,
    giveOwnershipHandler,
    demoteAdminHandler,
    cancelInvitationHandler,
  ])
  return groupFunctions
}
