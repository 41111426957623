import React from 'react'

type Props = {
  children: React.ReactNode
  active?: boolean
  last?: boolean
}

function NavbarGroup({ children, active, last }: Readonly<Props>) {
  return (
    <div className="mb-2 flex flex-row items-center justify-center gap-6">
      <div className="relative px-1">
        <div
          className={`absolute -bottom-1 left-0 h-[2px] w-full rounded-full bg-light-accent-2 duration-300 dark:bg-dark-accent-2 ${
            active ? 'scale-x-100' : 'scale-x-0'
          }`}
        ></div>
        <div>{children}</div>
      </div>

      {!last && (
        <div className="h-5 border-r border-light-primary-4 dark:border-dark-primary-4"></div>
      )}
    </div>
  )
}

export default NavbarGroup
