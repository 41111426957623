import {
  useState,
  useEffect,
  useRef,
  Dispatch,
  MutableRefObject,
  SetStateAction,
} from 'react'

export default function useComponentVisible(
  initialIsVisible: boolean
): [MutableRefObject<any>, boolean, Dispatch<SetStateAction<boolean>>] {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return [ref, isComponentVisible, setIsComponentVisible]
}
