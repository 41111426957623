import { useTranslation } from 'react-i18next'
import {
  Logins,
  Infos,
  StatusMessage,
  CountryCode,
} from '../utils/functions/types'
import Input from '../utils/components/Input'
import PhoneInput from './phone_input/PhoneInput'
import DividerWithText from '../utils/components/DividerWithText'
import { Link } from 'react-router-dom'
import { googleLogo } from '../../ressources/media/images'
import { FormEvent, useState } from 'react'
import 'react-phone-number-input/style.css'
import {
  checkNamesPhone,
  checkSignEmailPassword,
} from '../utils/functions/checkValues'

type Props = {
  logins: Logins
  infos: Infos
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setPassword: React.Dispatch<React.SetStateAction<string>>
  setFirstName: React.Dispatch<React.SetStateAction<string>>
  setName: React.Dispatch<React.SetStateAction<string>>
  setPhone: (phone: string) => void
  handleCreateUser: () => void
  signInGoogle: () => void
  openStatusMessage: (statusMessage: StatusMessage) => void
}

function Signup({
  logins,
  infos,
  setEmail,
  setPassword,
  setFirstName,
  setName,
  setPhone,
  handleCreateUser,
  signInGoogle,
  openStatusMessage,
}: Readonly<Props>) {
  const { t } = useTranslation()
  const [step, setStep] = useState(0 as number)
  const [confirmPassword, setConfirmPassword] = useState('' as string)
  const [country, setCountry] = useState('FR' as CountryCode)
  const handleNextStep = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (logins.password !== confirmPassword) {
      openStatusMessage({
        message: [t('passwordsNotMatching')],
        type: 'error',
      })
    } else {
      const status = await checkSignEmailPassword(logins.email, logins.password)
      if (status.type === 'error') {
        openStatusMessage(status)
      } else {
        setStep(step + 1)
      }
    }
  }
  const handleCreateAccount = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const status = await checkNamesPhone(
      infos.firstName,
      infos.name,
      infos.phone,
      country
    )
    if (status.type === 'error') {
      openStatusMessage(status)
    } else {
      handleCreateUser()
    }
  }

  return (
    <div
      id="signup-wrapper"
      className="flex w-full flex-col items-center duration-200"
    >
      <p className="mb-2 font-cookie text-5xl text-light-secondary-1 dark:text-dark-secondary-1 md:text-6xl">
        {t('signup')}
      </p>
      <div className="flex w-full flex-col items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center gap-3">
          {step === 0 && (
            <form
              onSubmit={handleNextStep}
              className="flex w-full flex-col items-center justify-center gap-3"
            >
              <Input
                type="email"
                value={logins.email}
                onChange={setEmail}
                placeholder={t('email')}
              />
              <Input
                type="password"
                value={logins.password}
                onChange={setPassword}
                placeholder={t('password')}
              />
              <Input
                type="password"
                value={confirmPassword}
                onChange={setConfirmPassword}
                placeholder={t('confirmPassword')}
              />
              <button className="font-md w-full rounded bg-light-accent-2 py-3 text-light-primary-0 duration-200 hover:bg-light-accent-3 dark:bg-dark-accent-2 dark:hover:bg-dark-accent-3">
                {t('continue')}
              </button>
            </form>
          )}
          {step === 1 && (
            <div>
              <button
                className="pb-2 text-left text-sm text-light-accent-2 duration-200 hover:text-light-accent-4 dark:text-dark-accent-2 dark:hover:text-dark-accent-4"
                onClick={() => setStep(step - 1)}
              >
                {t('back')}
              </button>
              <form
                onSubmit={handleCreateAccount}
                className="flex w-full flex-col items-center justify-center gap-3"
              >
                <Input
                  type="text"
                  placeholder={t('firstName')}
                  value={infos.firstName}
                  onChange={setFirstName}
                />
                <Input
                  type="text"
                  placeholder={t('lastName')}
                  value={infos.name}
                  onChange={setName}
                />
                <PhoneInput
                  placeholder={t('phoneNumber')}
                  value={infos.phone}
                  onChange={setPhone}
                  country={country}
                  setCountry={setCountry}
                />
                <button
                  type="submit"
                  className="font-md w-full rounded bg-light-accent-2 py-3 text-light-primary-0 duration-200 hover:bg-light-accent-3 dark:bg-dark-accent-2 dark:hover:bg-dark-accent-3"
                >
                  {t('createAccount')}
                </button>
              </form>
            </div>
          )}
          <Link to={'/auth/login'} className="flex flex-row gap-2 text-sm">
            <p className="text-light-secondary-1 dark:text-dark-secondary-1">
              {t('alreadyAccount?')}
            </p>
            <p className="text-light-accent-2 duration-200 hover:text-light-accent-3 dark:text-dark-accent-2 dark:hover:text-dark-accent-3">
              {t('login')}
            </p>
          </Link>
        </div>

        <div className="flex w-full flex-col items-center justify-center gap-3">
          <DividerWithText text={t('or')} />
          <button
            className="flex w-full flex-row items-center gap-4 rounded border border-light-secondary-4 p-4 py-3 text-left text-light-secondary-1 duration-200 hover:bg-light-primary-2 dark:border-dark-secondary-4 dark:text-dark-secondary-1 dark:hover:bg-dark-primary-2"
            onClick={signInGoogle}
          >
            <img className="h-5 w-5" src={googleLogo} alt="Google" />
            <p>{t('loginWithGoogle')}</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Signup
