import { doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore'
import {
  UserDataType,
  StatusMessage,
  EventType,
  InvitationType,
  NotificationsType,
  InvitationIdentifier,
} from '../types'
import { auth, db, firestoreAutoId } from './auth'
import { getUser } from './user'
import i18n from '../translation'
import { getGroup } from './group'
import { addMember } from './groupManagement'

export const getUserInvitations = async (
  user: UserDataType
): Promise<InvitationType[]> => {
  try {
    const notifications = (
      await getDoc(doc(db, 'notifications', user.notificationsId))
    ).data()
    return notifications.invitations as InvitationType[]
  } catch (error: any) {
    console.log('error while getting user invitations : ' + error.message)
    return null
  }
}

export const getUserEvents = async (
  user: UserDataType
): Promise<EventType[]> => {
  try {
    const notifications = (
      await getDoc(doc(db, 'notifications', user.notificationsId))
    ).data()
    return notifications.events as EventType[]
  } catch (error: any) {
    console.log('error while getting user events : ' + error.message)
    return null
  }
}

export const getUserNotifications = async (
  user: UserDataType
): Promise<NotificationsType> => {
  try {
    const notifications = (
      await getDoc(doc(db, 'notifications', user.notificationsId))
    ).data()
    return notifications as NotificationsType
  } catch (error: any) {
    console.log('error while getting user notifications : ' + error.message)
    return null
  }
}

export const addInvitation = async (
  groupId: string,
  sender: UserDataType,
  user: UserDataType
): Promise<StatusMessage> => {
  const notificationId = firestoreAutoId()
  // Add invitation to group
  try {
    await updateDoc(doc(db, 'groups', groupId), {
      invitations: arrayUnion({
        userId: user.id,
        invitationId: notificationId,
      }),
    })
  } catch (error: any) {
    console.log('error while adding invitation to group : ' + error.message)
    return { message: ['errorOccured'], type: 'error' }
  }
  try {
    let notification: InvitationType = {
      id: notificationId,
      to: user.id,
      from: sender.id,
      date: new Date(),
      group: groupId,
    }
    // Add invitation to user's notifications
    await updateDoc(doc(db, 'notifications', user.notificationsId), {
      invitations: arrayUnion(notification),
    })
  } catch (error: any) {
    console.log(
      "error while adding invitation to user's notifications : " + error.message
    )
    return { message: ['errorOccured'], type: 'error' }
  }
  return { message: ['invitationAdded'], type: 'success' }
}

export const findInvitation = async (
  userId: string,
  notificationId: string
): Promise<InvitationType> => {
  try {
    const user = await getUser(userId)
    // Get invitation
    const notifications = (
      await getDoc(doc(db, 'notifications', user.notificationsId))
    ).data()
    return notifications.invitations.find(
      (invitation: InvitationType) => invitation.id === notificationId
    )
  } catch (error: any) {
    console.log('error while finding invitation : ' + error.message)
    return null
  }
}

export const findEvent = async (
  userId: string,
  notificationId: string
): Promise<EventType> => {
  try {
    const user = await getUser(userId)
    // Get event
    const notifications = (
      await getDoc(doc(db, 'notifications', user.notificationsId))
    ).data()
    return notifications.events.find(
      (event: EventType) => event.id === notificationId
    )
  } catch (error: any) {
    console.log('error while finding event : ' + error.message)
    return null
  }
}

export const acceptInvitation = async (
  groupId: string,
  userId: string,
  notificationId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  const invitation = await findInvitation(auth.currentUser.uid, notificationId)
  if (invitation == null) {
    openStatusMessage({ message: ['notificationNotFound'], type: 'error' })
    return
  }
  addMember(groupId, userId, openStatusMessage)
  // Alert sender
  sendNewEvent(
    {
      id: firestoreAutoId(),
      to: invitation.from,
      date: new Date(),
      description: {
        eventType: 'invitationAccepted',
        info: [invitation.to, invitation.group],
      },
      seen: false,
    },
    openStatusMessage
  )
  const group = await getGroup(groupId)
  // Alert group members
  group.members.forEach((memberId) => {
    if (memberId === invitation.from || memberId === userId) return
    sendNewEvent(
      {
        id: firestoreAutoId(),
        to: memberId,
        date: new Date(),
        description: {
          eventType: 'userJoined',
          info: [invitation.to, invitation.group],
        },
        seen: false,
      },
      openStatusMessage
    )
  })
  deleteInvitation(groupId, userId, notificationId, openStatusMessage)
  openStatusMessage({ message: ['invitationAccepted'], type: 'success' })
}

const deleteInvitation = async (
  groupId: string,
  userId: string,
  invitationId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    // Remove invitation from group
    let invitations: InvitationIdentifier[] = (await getGroup(groupId))
      .invitations
    invitations = invitations.filter(
      (invitation) => invitation.invitationId !== invitationId
    )
    await updateDoc(doc(db, 'groups', groupId), {
      invitations: invitations,
    })
  } catch (error: any) {
    console.log('error while removing invitation from group : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
  deleteInvitationFromNotification(userId, invitationId, openStatusMessage)
}

export const cancelInvitation = async (
  groupId: string,
  userId: string,
  invitationId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  deleteInvitation(groupId, userId, invitationId, openStatusMessage)
  openStatusMessage({ message: ['invitationDeleted'], type: 'success' })
}

const deleteInvitationFromNotification = async (
  userId: string,
  invitationId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    // Remove invitation from user's notifications
    const user = (await getDoc(doc(db, 'users', userId))).data()
    try {
      let notifications: NotificationsType = (
        await getDoc(doc(db, 'notifications', user.notificationsId))
      ).data() as NotificationsType
      let invitations = notifications.invitations.filter(
        (invitation) => invitation.id !== invitationId
      )
      await updateDoc(doc(db, 'notifications', user.notificationsId), {
        invitations: invitations,
      })
    } catch (error: any) {
      console.log(
        "error while removing invitation from user's notifications : " +
          error.message
      )
      openStatusMessage({ message: ['errorOccured'], type: 'error' })
    }
  } catch (error: any) {
    console.log('error while getting user : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
}

export const declineInvitation = async (
  groupId: string,
  userId: string,
  notificationId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  // Check if notification exists
  const invitation = await findInvitation(userId, notificationId)
  if (invitation == null) {
    openStatusMessage({ message: ['notificationNotFound'], type: 'error' })
    return
  }
  // Alert sender
  sendNewEvent(
    {
      id: firestoreAutoId(),
      to: invitation.from,
      date: new Date(),
      description: {
        eventType: 'invitationDeclined',
        info: [invitation.to, invitation.group],
      },
      seen: false,
    },
    openStatusMessage
  )
  deleteInvitation(groupId, userId, notificationId, openStatusMessage)
  openStatusMessage({ message: ['invitationDeclined'], type: 'success' })
}

export const deleteEvent = async (
  userId: string,
  eventId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    // Remove event from user's notifications
    const user = await getUser(userId)
    try {
      let notifications: NotificationsType = await getUserNotifications(user)
      let events = notifications.events.filter((event) => event.id !== eventId)
      await updateDoc(doc(db, 'notifications', user.notificationsId), {
        events: events,
      })
    } catch (error: any) {
      console.log(
        "error while removing event from user's notifications : " +
          error.message
      )
      openStatusMessage({ message: ['errorOccured'], type: 'error' })
    }
  } catch (error: any) {
    console.log('error while getting user : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
}

export const sendNewEvent = async (
  event: EventType,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  // Check if receiver exists
  const receiver = await getUser(event.to)
  if (!receiver) {
    openStatusMessage({ message: ['userNotFound'], type: 'error' })
    return
  }
  // Send event to receiver
  try {
    await updateDoc(doc(db, 'notifications', receiver.notificationsId), {
      events: arrayUnion(event),
    })
  } catch (error: any) {
    console.log('error while sending event to receiver : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
}

export const getEventMessage = async (event: EventType): Promise<string> => {
  switch (event.description.eventType) {
    case 'invitationAccepted':
      if (event.description.info.length !== 2) return ''
      return (
        (await getUser(event.description.info[0])).firstName +
        ' ' +
        i18n.t('acceptedYourInvite') +
        ' ' +
        (await getGroup(event.description.info[1])).name
      )
    case 'invitationDeclined':
      if (event.description.info.length !== 2) return ''
      return (
        (await getUser(event.description.info[0])).firstName +
        ' ' +
        i18n.t('declinedYourInvite') +
        ' ' +
        (await getGroup(event.description.info[1])).name
      )
    case 'userJoined':
      if (event.description.info.length !== 2) return ''
      return (
        (await getUser(event.description.info[0])).firstName +
        ' ' +
        i18n.t('joinedTheGroup') +
        ' ' +
        (await getGroup(event.description.info[1])).name
      )
    case 'userLeft':
      if (event.description.info.length !== 2) return ''
      return (
        (await getUser(event.description.info[0])).firstName +
        ' ' +
        i18n.t('leftTheGroup') +
        ' ' +
        (await getGroup(event.description.info[1])).name
      )
    case 'userKicked':
      if (event.description.info.length !== 3) return ''
      return (
        (await getUser(event.description.info[0])).firstName +
        ' ' +
        i18n.t('hasKicked') +
        ' ' +
        (await getUser(event.description.info[1])).firstName +
        ' ' +
        i18n.t('fromTheGroup') +
        ' ' +
        (await getGroup(event.description.info[2])).name
      )
  }
}

export const getInvitationMessage = async (
  invitation: InvitationType
): Promise<string> => {
  return (
    (await getUser(invitation.from)).firstName +
    ' ' +
    i18n.t('invitedYouToJoin') +
    ' ' +
    (await getGroup(invitation.group)).name
  )
}

export const markEventsAsSeen = async (userId: string): Promise<void> => {
  try {
    const user = await getUser(userId)
    const events = await getUserEvents(user)
    events.forEach((event) => {
      event.seen = true
    })
    await updateDoc(doc(db, 'notifications', user.notificationsId), {
      events: events,
    })
  } catch (error: any) {
    console.log('error while marking events as seen : ' + error.message)
  }
}
