import { useMemo } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  children: React.ReactNode
  icon?: JSX.Element
  link?: string
  active?: boolean
  onClick?: () => void
  sideAction?: React.ReactNode
}

function MobileMenuLink({
  children,
  icon,
  link,
  active,
  onClick,
  sideAction,
}: Readonly<Props>) {
  const textClasses = useMemo(() => {
    return (
      'block w-full text-left text-xl duration-200  xs:text-2xl' +
      (active
        ? ' text-light-accent-3 dark:text-dark-accent-3'
        : 'text-light-secondary-1 dark:text-dark-secondary-1')
    )
  }, [active])

  return (
    <li className="flex flex-row items-center gap-3 border-b border-light-primary-3 py-2 dark:border-dark-primary-3">
      {icon && (
        <div className="flex h-6 w-6 flex-row items-center text-xl text-light-secondary-2 dark:text-dark-secondary-2 xs:text-2xl">
          {icon}
        </div>
      )}
      <div className="flex w-full flex-row justify-between">
        {link ? (
          <Link to={link} className={textClasses} onClick={onClick}>
            {children}
          </Link>
        ) : (
          <button className={textClasses} onClick={onClick}>
            {children}
          </button>
        )}
        {sideAction || null}
      </div>
    </li>
  )
}
export default MobileMenuLink
