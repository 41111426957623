import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'

type Props = {
  children: React.ReactNode
  isOpened: boolean
  close: () => void
}

function Popup({ children, isOpened, close }: Props) {
  const [opened, setOpened] = useState(false)
  const [transition, setTransition] = useState(false)
  useEffect(() => {
    if (isOpened) {
      setOpened(true)
      setTimeout(() => {
        setTransition(true)
      }, 200)
    } else {
      setTransition(false)
      setTimeout(() => {
        setOpened(false)
      }, 200)
    }
  }, [isOpened])
  return (
    opened && (
      <div className="fixed inset-0 z-40 flex flex-col items-center justify-center">
        <div className="relative z-50 mb-16 flex w-full max-w-[38rem] flex-col items-center justify-center px-6">
          <div
            className={`flex w-full flex-col items-center justify-center ${
              transition ? 'scale-100' : 'scale-0'
            } md-8 rounded-lg border border-light-primary-3 bg-light-primary-1 p-4 shadow duration-200 dark:border-dark-primary-3 dark:bg-dark-primary-1`}
          >
            <button className="absolute right-2 top-2" onClick={close}>
              <div
                style={{ fontSize: '1.5rem' }}
                className="cursor-pointer rounded-lg p-1.5 text-light-secondary-4 duration-200 hover:bg-light-primary-2 focus:outline-none focus:ring-2 focus:ring-light-primary-2 dark:text-dark-secondary-4 dark:hover:bg-dark-primary-2 dark:focus:ring-dark-primary-2"
              >
                <MdClose />
              </div>
            </button>
            <div className="flex w-full flex-col items-center justify-center gap-4 px-2">
              {children}
            </div>
          </div>
        </div>
        <button
          className={`absolute inset-0 z-40 overflow-hidden bg-black ${
            transition ? 'opacity-30' : 'opacity-0'
          } duration-200`}
          onClick={close}
        ></button>
      </div>
    )
  )
}

export default Popup
