import { initializeApp } from 'firebase/app'
import {
  Firestore,
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
  where,
  query,
  getDocs,
} from 'firebase/firestore'
import {
  Auth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth'
import { Infos, Logins, StatusMessage } from '../types'

const firebaseConfig = {
  apiKey: 'AIzaSyD_FaY9b2ZDpJzKs7OOHXcWrLGn7HdYFeU',
  authDomain: 'secretgifts-2.firebaseapp.com',
  projectId: 'secretgifts-2',
  storageBucket: 'secretgifts-2.appspot.com',
  messagingSenderId: '46133241659',
  appId: '1:46133241659:web:9e5dceeb24cfcc2514b91e',
}

const app = initializeApp(firebaseConfig)
const db: Firestore = getFirestore(app)
const auth: Auth = getAuth(app)
auth.useDeviceLanguage()

export { db, auth }

export const firestoreAutoId = (): string => {
  const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  let autoId = ''

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length))
  }
  return autoId
}

export const isAccountFromProvider = (): boolean => {
  return auth.currentUser.providerData[0].providerId !== 'password'
}

export const emailExists = async (email: string): Promise<boolean> => {
  try {
    const q = query(collection(db, 'users'), where('email', '==', email))
    const userDoc = await getDocs(q)
    return !userDoc.empty
  } catch (error: any) {
    console.log('error while checking if email exists : ' + error.message)
    return null
  }
}

export const phoneExists = async (phone: string): Promise<boolean> => {
  try {
    const q = query(collection(db, 'users'), where('phone', '==', phone))
    const userDoc = await getDocs(q)
    return !userDoc.empty
  } catch (error: any) {
    console.log('error while checking if phone exists : ' + error.message)
    return null
  }
}

export const createUserMail = async (
  infos: Infos,
  logins: Logins,
  openStatusMessage: (status: StatusMessage) => void,
  resetCredentials: () => void
): Promise<void> => {
  try {
    const notifications = await addDoc(collection(db, 'notifications'), {
      invitations: [],
      events: [],
    })
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        logins.email,
        logins.password
      )
      const user = userCredential.user
      try {
        await setDoc(doc(db, 'users', user.uid), {
          name: infos.name,
          firstName: infos.firstName,
          phone: infos.phone,
          email: logins.email,
          signupDate: new Date(),
          locale: 'fr',
          groups: [],
          notificationsId: notifications.id,
        })

        openStatusMessage({
          message: ['accountCreated'],
          type: 'success',
        })
        resetCredentials()
      } catch (error: any) {
        console.log("error while creating user's documents : " + error.message)
        openStatusMessage({ message: [error.message], type: 'error' })
      }
    } catch (error: any) {
      console.log(
        "error while creating user's authentication : " + error.message
      )
      openStatusMessage({ message: [error.message], type: 'error' })
    }
  } catch (error: any) {
    console.log('error while creating notifications : ' + error.message)
    openStatusMessage({ message: [error.message], type: 'error' })
  }
}

export const signInEmail = async (
  logins: Logins,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    await signInWithEmailAndPassword(auth, logins.email, logins.password)
    openStatusMessage({ message: ['logInSuccessful'], type: 'success' })
  } catch (error: any) {
    console.log('error while signing in : ' + error.message)
    openStatusMessage({ message: [error.message], type: 'error' })
  }
}

export const signInGoogle = async (
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    const provider = new GoogleAuthProvider()
    const result = await signInWithPopup(auth, provider)
    const credential = GoogleAuthProvider.credentialFromResult(result)
    const token = credential.accessToken
    const apiUrl = `https://www.googleapis.com/oauth2/v2/userinfo?access_token=${token}`
    const data = await (await fetch(apiUrl)).json()
    const exists = await emailExists(data.email)
    if (exists) {
      openStatusMessage({
        message: ['signInSuccessful'],
        type: 'success',
      })
      return
    }
    try {
      const notifications = await addDoc(collection(db, 'notifications'), {
        invitations: [],
        events: [],
      })
      await setDoc(doc(db, 'users', result.user.uid), {
        name: data.family_name ? data.family_name : '',
        firstName: data.given_name,
        phone: data.phone_number ? data.phone_number : '',
        email: data.email,
        signupDate: new Date(),
        locale: data.locale,
        groups: [],
        notificationsId: notifications.id,
      })
      openStatusMessage({
        message: ['signUpSuccessful'],
        type: 'success',
      })
    } catch (error: any) {
      console.log('error while creating user : ' + error.message)
      openStatusMessage({ message: [error.message], type: 'error' })
    }
  } catch (error: any) {
    console.log('error while signing in : ' + error.message)
    openStatusMessage({ message: [error.message], type: 'error' })
  }
}

export const logout = async (): Promise<void> => {
  try {
    await auth.signOut()
  } catch (error: any) {
    console.log('error while logging out : ' + error.message)
  }
}
