import {
  doc,
  addDoc,
  collection,
  updateDoc,
  arrayUnion,
  getDoc,
  arrayRemove,
  deleteDoc,
} from 'firebase/firestore'
import { StatusMessage, UserDataType, GroupDataType } from '../types'
import { db, auth, firestoreAutoId } from './auth'
import {
  getUserInvitations,
  addInvitation,
  sendNewEvent,
} from './notifications'
import { getUserFromEmail } from './user'
import { getGroup } from './group'

export const createGroup = async (
  name: string,
  description: string,
  giftsPerUser: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<string> => {
  try {
    let groupId = await addDoc(collection(db, 'groups'), {
      name: name,
      description: description,
      giftsPerUser: giftsPerUser,
      members: [auth.currentUser.uid],
      admins: [],
      owner: auth.currentUser.uid,
      invitations: [],
      status: 'noCurrentGifts',
    })
    try {
      await updateDoc(doc(db, 'users', auth.currentUser.uid), {
        groups: arrayUnion(groupId.id),
      })
      return groupId.id
    } catch (error: any) {
      console.log('error while adding group to user : ' + error.message)
      openStatusMessage({ message: ['errorOccured'], type: 'error' })
      return null
    }
  } catch (error: any) {
    console.log('error while creating group : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
    return null
  }
}

export const sendInvitation = async (
  groupId: string,
  sender: UserDataType,
  email: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  // Check if user exists
  try {
    const user = await getUserFromEmail(email)
    if (!user) {
      openStatusMessage({ message: ['userNotFound'], type: 'error' })
      return
    }
    // Check if user is already in group
    if (user.groups.includes(groupId)) {
      openStatusMessage({ message: ['userAlreadyInGroup'], type: 'error' })
      return
    }
    try {
      // Check if user is already invited
      const invitations = await getUserInvitations(user)
      if (invitations.find((inv) => inv.group === groupId)) {
        openStatusMessage({ message: ['userAlreadyInvited'], type: 'error' })
        return
      }
      try {
        // Add invitation
        const additionStatus = await addInvitation(groupId, sender, user)
        if (additionStatus.type === 'error') {
          openStatusMessage(additionStatus)
          return
        }
      } catch (error: any) {
        openStatusMessage({ message: ['errorOccured'], type: 'error' })
        return
      }
    } catch (error: any) {
      openStatusMessage({ message: ['errorOccured'], type: 'error' })
      return
    }
  } catch (error: any) {
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
    return
  }
  openStatusMessage({ message: ['invitationSent'], type: 'success' })
}

export const addMember = async (
  groupId: string,
  userId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  // Add user to group
  await updateDoc(doc(db, 'groups', groupId), {
    members: arrayUnion(userId),
  })
  try {
    // Add group to user
    const user = await getDoc(doc(db, 'users', userId))
    try {
      await updateDoc(doc(db, 'users', user.id), {
        groups: arrayUnion(groupId),
      })
    } catch (error: any) {
      console.log('error while adding group to user : ' + error.message)
      openStatusMessage({ message: ['errorOccured'], type: 'error' })
    }
  } catch (error: any) {
    console.log('error while getting user : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
}

const removeMember = async (
  groupId: string,
  userId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  console.log(groupId, userId)
  try {
    await updateDoc(doc(db, 'groups', groupId), {
      members: arrayRemove(userId),
    })
  } catch (error: any) {
    console.log('error while removing member from group : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
  try {
    await updateDoc(doc(db, 'users', userId), {
      groups: arrayRemove(groupId),
    })
  } catch (error: any) {
    console.log('error while removing group from user : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
}

export const kickMember = async (
  groupId: string,
  userId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  await removeMember(groupId, userId, openStatusMessage)
  // Alert other members
  const group = await getGroup(groupId)
  group.members.forEach((memberId) => {
    if (memberId === userId || memberId === auth.currentUser.uid) return
    sendNewEvent(
      {
        id: firestoreAutoId(),
        to: memberId,
        date: new Date(),
        description: {
          eventType: 'userKicked',
          info: [userId, auth.currentUser.uid, groupId],
        },
        seen: false,
      },
      openStatusMessage
    )
  })
  openStatusMessage({ message: ['memberKicked'], type: 'success' })
}

export const leaveGroup = async (
  group: GroupDataType,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  if (group.owner === auth.currentUser.uid) {
    openStatusMessage({ message: ['ownerCannotLeave'], type: 'error' })
    return
  }
  try {
    await updateDoc(doc(db, 'users', auth.currentUser.uid), {
      groups: arrayRemove(group.id),
    })
  } catch (error: any) {
    console.log('error while removing group from user : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
  if (group.members.length === 1) {
    try {
      await deleteGroup(group.id, openStatusMessage).catch((error) => {
        console.log(error.message)
        openStatusMessage({ message: ['errorOccured'], type: 'error' })
      })
    } catch (error: any) {
      console.log('error while deleting group : ' + error.message)
      openStatusMessage({ message: ['errorOccured'], type: 'error' })
    }
  } else {
    try {
      await updateDoc(doc(db, 'groups', group.id), {
        members: arrayRemove(auth.currentUser.uid),
      })
    } catch (error: any) {
      console.log('error while removing member from group : ' + error.message)
      openStatusMessage({ message: ['errorOccured'], type: 'error' })
    }
  }
  // Alert other members
  group.members.forEach((memberId) => {
    if (memberId === auth.currentUser.uid) return
    sendNewEvent(
      {
        id: firestoreAutoId(),
        to: memberId,
        date: new Date(),
        description: {
          eventType: 'userLeft',
          info: [auth.currentUser.uid, group.id],
        },
        seen: false,
      },
      openStatusMessage
    )
  })
  openStatusMessage({ message: ['groupLeft'], type: 'success' })
}

export const promoteToAdmin = async (
  groupId: string,
  userId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    await updateDoc(doc(db, 'groups', groupId), {
      admins: arrayUnion(userId),
    })
  } catch (error: any) {
    console.log('error while promoting member to admin : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
  openStatusMessage({ message: ['memberPromoted'], type: 'success' })
}

export const demoteAdmin = async (
  groupId: string,
  userId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    await updateDoc(doc(db, 'groups', groupId), {
      admins: arrayRemove(userId),
    })
  } catch (error: any) {
    console.log('error while demoting admin : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
  openStatusMessage({ message: ['memberDemoted'], type: 'success' })
}

export const modifyGroupName = async (
  groupId: string,
  name: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    await updateDoc(doc(db, 'groups', groupId), {
      name: name,
    })
  } catch (error: any) {
    console.log('error while modifying group name : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
  openStatusMessage({ message: ['groupNameModified'], type: 'success' })
}

export const modifyGroupDescription = async (
  groupId: string,
  description: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    await updateDoc(doc(db, 'groups', groupId), {
      description: description,
    })
  } catch (error: any) {
    console.log('error while modifying group description : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
  openStatusMessage({ message: ['groupDescriptionModified'], type: 'success' })
}

export const giveOwnership = async (
  groupId: string,
  userId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    await updateDoc(doc(db, 'groups', groupId), {
      owner: userId,
    })
  } catch (error: any) {
    console.log('error while giving ownership : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
  openStatusMessage({ message: ['ownershipChanged'], type: 'success' })
}

export const deleteGroup = async (
  groupId: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    let group = await getDoc(doc(db, 'groups', groupId))
    let members = group.data().members
    for (const member of members) {
      try {
        await updateDoc(doc(db, 'users', member), {
          groups: arrayRemove(groupId),
        })
      } catch (error: any) {
        console.log('error while removing group from users : ' + error.message)
        openStatusMessage({ message: ['errorOccured'], type: 'error' })
      }
    }
  } catch (error: any) {
    console.log('error while getting group : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
  try {
    await deleteDoc(doc(db, 'groups', groupId)).catch((error) => {
      console.log(error.message)
      openStatusMessage({ message: ['errorOccured'], type: 'error' })
    })
  } catch (error: any) {
    console.log("error while deleting group's document : " + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
  openStatusMessage({ message: ['groupDeleted'], type: 'success' })
}
