import { useTranslation } from 'react-i18next'

type Props = {
  text: string
  selected: boolean
  onClick: () => void
}

function GroupTabButton({ text, selected, onClick }: Readonly<Props>) {
  const { t } = useTranslation()
  return (
    <li className="w-1/3 md:w-1/4">
      <button
        data-tabs-target={`#${text}`}
        type="button"
        role="tab"
        aria-controls={text}
        aria-selected={selected}
        className={`relative inline-block w-full bg-opacity-70 p-4 text-base text-light-secondary-4 hover:bg-light-primary-2 dark:text-dark-secondary-4 dark:hover:bg-dark-primary-2`}
        onClick={onClick}
      >
        <p>{t(text)}</p>
      </button>
    </li>
  )
}

export default GroupTabButton
