type Props = {
  children: React.ReactNode
  fieldName: string
}

function BareDisplayEditField({ children, fieldName }: Readonly<Props>) {
  return (
    <div className="flex w-full flex-col items-center justify-between gap-4 md:w-auto md:flex-row">
      <p className="whitespace-nowrap text-2xl text-light-secondary-2 duration-200 dark:text-dark-secondary-2">
        {fieldName}
      </p>
      {children}
    </div>
  )
}

export default BareDisplayEditField
