import { useCallback } from 'react'
import { GroupFunctions, UserGroupContext } from '../../utils/functions/types'
import { FaCrown, FaShieldAlt } from 'react-icons/fa'
import { MdMoreHoriz } from 'react-icons/md'
import { profile } from '../../../ressources/media/images'
import { useTranslation } from 'react-i18next'
import Tooltip from '../../utils/components/Tooltip'
import useComponentVisible from '../../utils/hooks/useComponentVisible'
import GroupMemberDropdown from './GroupMemberDropdown'
import { Link } from 'react-router-dom'
import { getFullName } from '../../utils/functions/firebase/user'

type Props = {
  targetGroupContext: UserGroupContext
  userGroupContext: UserGroupContext
  groupFunctions: GroupFunctions
  invitationId?: string
}

function GroupMemberCard({
  targetGroupContext,
  userGroupContext,
  groupFunctions,
  invitationId,
}: Props) {
  const { t } = useTranslation()
  const [ref, dropdown, setDropdown] = useComponentVisible(false)

  const targetName = getFullName(targetGroupContext)

  const toggleDropdown = useCallback(() => {
    setDropdown(!dropdown)
  }, [dropdown, setDropdown])

  return (
    targetGroupContext && (
      <div className="relative flex h-60 min-w-[15rem] max-w-[18rem] flex-1 flex-col rounded-lg border border-light-primary-2 bg-light-primary-0 p-4 shadow dark:border-dark-primary-2 dark:bg-dark-primary-0 dark:bg-opacity-80">
        <div className="absolute left-0 flex w-full justify-between px-4">
          <div className="flex flex-row items-center gap-2">
            {targetGroupContext.owner && (
              <div className="relative">
                <div
                  style={{ fontSize: '1.5rem' }}
                  className="peer flex h-7 cursor-pointer items-center text-yellow-400 dark:text-yellow-500"
                >
                  <FaCrown />
                </div>
                <Tooltip text={t('owner')} />
              </div>
            )}
            {targetGroupContext.admin && (
              <div className="relative">
                <div
                  style={{ fontSize: '1.25rem' }}
                  className="peer flex h-7 cursor-pointer items-center text-slate-400 dark:text-slate-500"
                >
                  <FaShieldAlt />
                </div>
                <Tooltip text={t('admin')} />
              </div>
            )}
          </div>
          <div ref={ref} className="relative flex flex-col items-center">
            <button
              data-dropdown-toggle="dropdown"
              style={{ fontSize: '1.5rem' }}
              className="dak:focus:ring-dark-primary-2 inline-block rounded-lg p-1.5 text-sm text-neutral duration-200 hover:bg-light-primary-1 focus:outline-none focus:ring-2 focus:ring-light-primary-2 dark:hover:bg-dark-primary-1"
              type="button"
              onClick={toggleDropdown}
            >
              <MdMoreHoriz />
            </button>
            {dropdown && (
              <GroupMemberDropdown
                containerRef={ref}
                targetGroupContext={targetGroupContext}
                userGroupContext={userGroupContext}
                groupFunctions={groupFunctions}
                closeDropdown={() => setDropdown(false)}
                invitationId={invitationId}
              />
            )}
          </div>
        </div>
        <div className="flex h-full flex-col items-center justify-around pt-9">
          <img
            className="h-12 w-12 rounded-full shadow-lg"
            src={profile}
            alt={targetName}
          />
          <h5
            className={`line-clamp-2 text-center font-medium ${
              userGroupContext.id === targetGroupContext.id
                ? 'text-light-accent-3 dark:text-dark-accent-3'
                : 'text-light-secondary-1 dark:text-dark-secondary-1'
            }`}
          >
            {targetName}
          </h5>
          <span className="w-full truncate text-center text-xs text-light-secondary-4 dark:text-dark-secondary-3">
            {targetGroupContext.email}
          </span>
          <div className="mt-4 flex space-x-3 md:mt-6">
            <Link
              to={
                userGroupContext.id === targetGroupContext.id
                  ? '/profile'
                  : `/profile/${targetGroupContext.id}`
              }
              className="flex items-center rounded-lg bg-light-accent-2 px-4 py-2 text-center text-sm font-medium text-light-primary-0 duration-200 hover:bg-light-accent-1 focus:outline-none focus:ring-4 focus:ring-light-accent-1 dark:bg-dark-accent-2 dark:hover:bg-dark-accent-1 dark:focus:ring-dark-accent-1"
            >
              <p className="whitespace-nowrap text-xs">{t('seeProfile')}</p>
            </Link>
            <Link
              to={null}
              className="inline-flex items-center rounded-lg border border-light-primary-3 bg-light-primary-0 px-4 py-2 text-center text-sm font-medium text-light-secondary-1 duration-200 hover:bg-light-primary-1 focus:outline-none focus:ring-4 focus:ring-light-primary-2 dark:border-dark-primary-3 dark:bg-dark-primary-0 dark:text-dark-secondary-1 dark:hover:bg-dark-primary-1 dark:focus:ring-dark-primary-2"
            >
              <p className="whitespace-nowrap text-xs">{t('message')}</p>
            </Link>
          </div>
        </div>
      </div>
    )
  )
}

export default GroupMemberCard
