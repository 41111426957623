import { useCallback } from 'react'
import { MdSettings, MdNightlightRound, MdLogout } from 'react-icons/md'
import { logout } from '../../utils/functions/firebase/auth'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import useComponentVisible from '../../utils/hooks/useComponentVisible'
import Dropdown from '../../utils/components/dropdown/Dropdown'
import DropdownGroup from '../../utils/components/dropdown/DropdownGroup'
import DropdownItem from '../../utils/components/dropdown/DropdownItem'
import CheckButton from '../../utils/components/CheckButton'
import IconButton from '../../utils/components/IconButton'

type Props = {
  darkMode: boolean
  toggleDarkMode: () => void
}

function UserSettings({ darkMode, toggleDarkMode }: Readonly<Props>) {
  const { t } = useTranslation()
  const location = useLocation()
  const [ref, dropdown, setDropdown] = useComponentVisible(false)

  const dropdownClickHandler = useCallback(() => {
    if (dropdown) {
      setDropdown(false)
    } else {
      setDropdown(true)
    }
  }, [dropdown, setDropdown])

  return (
    <div ref={ref} className="relative flex h-10 w-10 flex-col items-center">
      <IconButton icon={<MdSettings />} onClick={dropdownClickHandler} />
      {dropdown && (
        <Dropdown containerRef={ref} xOffSet={'-32%'} yOffSet="6px">
          <DropdownGroup>
            <DropdownItem
              active={location.pathname === '/settings'}
              to="/settings"
            >
              <div className="flex flex-row items-center gap-2">
                <div style={{ fontSize: '1.25rem' }} className="mt-px">
                  <MdSettings />
                </div>
                <p>{t('settings')}</p>
              </div>
            </DropdownItem>
            <DropdownItem
              onClick={toggleDarkMode}
              sideActions={
                <CheckButton checked={darkMode} onChange={toggleDarkMode} />
              }
            >
              <div className="relative flex flex-row items-center">
                <div className="flex flex-row items-center gap-2 pr-4">
                  <div
                    style={{ fontSize: '1.25rem' }}
                    className="mt-px -rotate-[30deg]"
                  >
                    <MdNightlightRound />
                  </div>
                  <p>{t('darkTheme')}</p>
                </div>
              </div>
            </DropdownItem>
            <DropdownItem onClick={logout}>
              <div className="flex flex-row items-center gap-2">
                <div
                  style={{ fontSize: '1.25rem' }}
                  className="mt-px -translate-x-px rotate-180"
                >
                  <MdLogout />
                </div>
                <p>{t('logout')}</p>
              </div>
            </DropdownItem>
          </DropdownGroup>
        </Dropdown>
      )}
    </div>
  )
}

export default UserSettings
