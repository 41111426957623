import { Dispatch, SetStateAction, useState } from 'react'
import { Logins } from '../../utils/functions/types'

export const useLogins = (): [
  logins: Logins,
  setEmail: Dispatch<SetStateAction<string>>,
  setPassword: Dispatch<SetStateAction<string>>,
  resetInputs: () => void
] => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const Logins = { email, password }
  const resetInputs = (): void => {
    setEmail('')
    setPassword('')
  }

  return [Logins, setEmail, setPassword, resetInputs]
}
