import { useTranslation } from 'react-i18next'
import { MdDelete } from 'react-icons/md'
import { EventType } from '../../../../utils/functions/types'
import { deleteEvent } from '../../../../utils/functions/firebase/notifications'
import IconButton from '../../../../utils/components/IconButton'

type Props = {
  event: EventType
}

function EventActions({ event }: Readonly<Props>) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-row items-center justify-around px-2 text-2xl md:text-3xl">
      <IconButton
        icon={<MdDelete />}
        onClick={() => {
          deleteEvent(event.to, event.id, (statusMessage) => {
            console.log(statusMessage)
          })
        }}
        fontSize="2rem"
        forceIconClass="text-light-accent-1 dark:text-dark-accent-1"
        tooltip={{
          text: t('remove'),
        }}
      />
    </div>
  )
}

export default EventActions
