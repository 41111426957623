import { t } from 'i18next'
import Dropdown from '../../utils/components/dropdown/Dropdown'
import DropdownGroup from '../../utils/components/dropdown/DropdownGroup'
import DropdownItem from '../../utils/components/dropdown/DropdownItem'
import { UserGroupContext, GroupFunctions } from '../../utils/functions/types'

type Props = {
  containerRef: React.RefObject<HTMLElement>
  targetGroupContext: UserGroupContext
  userGroupContext: UserGroupContext
  groupFunctions: GroupFunctions
  closeDropdown: () => void
  invitationId?: string
}

function GroupMemberDropdown({
  containerRef,
  targetGroupContext,
  userGroupContext,
  groupFunctions,
  invitationId,
  closeDropdown,
}: Readonly<Props>) {
  const userIsTarget = userGroupContext.id === targetGroupContext.id
  const canLeaveGroup =
    userIsTarget && !userGroupContext.owner && userGroupContext.member
  const canPromoteAdmin =
    !userIsTarget &&
    userGroupContext.owner &&
    targetGroupContext.member &&
    !targetGroupContext.admin
  const canDemoteAdmin =
    !userIsTarget &&
    userGroupContext.owner &&
    targetGroupContext.member &&
    targetGroupContext.admin
  const canPassOwnership =
    !userIsTarget && userGroupContext.owner && targetGroupContext.member
  const haveAuthority =
    !userIsTarget &&
    (userGroupContext.owner ||
      (userGroupContext.admin &&
        !targetGroupContext.owner &&
        !targetGroupContext.admin))
  const canRemoveMember = haveAuthority && targetGroupContext.member
  const canDeleteInvitation = haveAuthority && !targetGroupContext.member

  return (
    <Dropdown containerRef={containerRef} xOffSet="-33%" yOffSet="0.4rem">
      <DropdownGroup>
        <DropdownItem
          to={
            userGroupContext.id === targetGroupContext.id
              ? '/profile'
              : `/profile/${targetGroupContext.id}`
          }
          textAlign="center"
        >
          {t('seeProfile')}
        </DropdownItem>

        {canPromoteAdmin && (
          <DropdownItem
            onClick={() => {
              groupFunctions.owner?.promoteToAdmin(targetGroupContext.id)
              closeDropdown()
            }}
            textAlign="center"
          >
            {t('promoteAdmin')}
          </DropdownItem>
        )}
        {canDemoteAdmin && (
          <DropdownItem
            onClick={() => {
              groupFunctions.owner?.demoteAdmin(targetGroupContext.id)
              closeDropdown()
            }}
            textAlign="center"
          >
            {t('demoteAdmin')}
          </DropdownItem>
        )}
        {canPassOwnership && (
          <DropdownItem
            onClick={() => {
              groupFunctions.owner?.giveOwnership(targetGroupContext.id)
              closeDropdown()
            }}
            textAlign="center"
          >
            {t('giveOwnership')}
          </DropdownItem>
        )}
        {userIsTarget && (
          <DropdownItem to={'/profile'} textAlign="center">
            {t('edit')}
          </DropdownItem>
        )}
        {canLeaveGroup && (
          <DropdownItem
            onClick={() =>
              groupFunctions.member?.leaveGroup(targetGroupContext.id)
            }
            active
            textAlign="center"
          >
            {t('leaveGroup')}
          </DropdownItem>
        )}
        {canRemoveMember && (
          <DropdownItem
            onClick={() => {
              groupFunctions.admin?.removeMember(targetGroupContext.id)
              closeDropdown()
            }}
            active={true}
            textAlign="center"
          >
            {targetGroupContext.member ? t('kick') : t('cancelInvitation')}
          </DropdownItem>
        )}
        {canDeleteInvitation && (
          <DropdownItem
            onClick={() => {
              groupFunctions.admin?.cancelInvitation(
                targetGroupContext.id,
                invitationId
              )
              closeDropdown()
            }}
            active={true}
            textAlign="center"
          >
            {t('deleteInvitation')}
          </DropdownItem>
        )}
      </DropdownGroup>
    </Dropdown>
  )
}

export default GroupMemberDropdown
