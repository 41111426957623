import {
  GroupDataType,
  InvitedMember,
  UserDataType,
} from '../../../utils/functions/types'
import { getDoc, doc, Firestore } from 'firebase/firestore'
import { useEffect, useState } from 'react'

export const useMembers = (
  db: Firestore,
  group: GroupDataType
): [
  owner: UserDataType,
  members: UserDataType[],
  invitedMembers: InvitedMember[],
] => {
  const [owner, setOwner] = useState<UserDataType>()
  const [members, setMembers] = useState<UserDataType[]>([])
  const [invitedMembers, setInvitedMembers] = useState<InvitedMember[]>([])

  useEffect(() => {
    const fetchMembers = async (group: GroupDataType) => {
      let m = []
      let i = []
      for (const memberId of group.members) {
        const memberDoc = await getDoc(doc(db, 'users', memberId))
        let member = memberDoc.data() as UserDataType
        member.id = memberId
        if (memberId === group.owner) setOwner(member)
        m.push(member)
      }
      for (const invitationIdentifier of group.invitations) {
        const invitedDoc = await getDoc(
          doc(db, 'users', invitationIdentifier.userId)
        )
        let member = invitedDoc.data() as UserDataType
        member.id = invitationIdentifier.userId
        i.push({
          user: member,
          invitationId: invitationIdentifier.invitationId,
        })
      }
      return { members: m, invited: i }
    }
    if (group) {
      fetchMembers(group).then(({ members: m, invited: i }) => {
        setMembers(m)
        setInvitedMembers(i)
      })
    }
  }, [db, group])
  return [owner, members, invitedMembers]
}
