import { t } from 'i18next'
import { checkEmail } from '../../utils/functions/checkValues'
import { StatusMessage, UserDataType } from '../../utils/functions/types'
import SimplePopupInputForm from '../../utils/components/popup/popup_content/SimplePopupInputForm'

function AddMember({
  handleAddMember,
  user,
}: Readonly<{
  handleAddMember: (email: string) => void
  user: UserDataType
}>) {
  const handleSubmit = (
    inputs: { [input: string]: string },
    openStatusMessage: (status: StatusMessage) => void
  ) => {
    let status = checkEmail(inputs['email'])
    if (status.type === 'error') {
      openStatusMessage(status)
      return
    }
    if (user.email === inputs['email']) {
      openStatusMessage({
        message: [t('cannotAddYourself')],
        type: 'error',
      })
      return
    }
    handleAddMember(inputs['email'])
  }

  return (
    <SimplePopupInputForm
      inputs={{ email: 'email' }}
      onSubmit={handleSubmit}
      title={t('addMember')}
      placeholder={t('email')}
      submitText={t('invite')}
    />
  )
}

export default AddMember
