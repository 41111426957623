import { Link } from 'react-router-dom'
import { Logins, StatusMessage } from '../utils/functions/types'
import Input from '../utils/components/Input'
import { t } from 'i18next'
import { googleLogo } from '../../ressources/media/images'
import DividerWithText from '../utils/components/DividerWithText'
import { checkEmailPassword } from '../utils/functions/checkValues'
import { FormEvent } from 'react'

type Props = {
  logins: Logins
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setPassword: React.Dispatch<React.SetStateAction<string>>
  signInEmail: () => void
  signInGoogle: () => void
  openStatusMessage: (statusMessage: StatusMessage) => void
}

function Login({
  logins,
  setEmail,
  setPassword,
  signInEmail,
  signInGoogle,
  openStatusMessage,
}: Readonly<Props>) {
  const handleSignIn = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const status = checkEmailPassword(logins.email, logins.password)
    if (status.type === 'error') {
      openStatusMessage(status)
    } else {
      signInEmail()
    }
  }

  return (
    <div
      id={'login-wrapper'}
      className="flex w-full flex-col items-center duration-200"
    >
      <p className="mb-2 font-cookie text-6xl text-light-secondary-1 dark:text-dark-secondary-1">
        {t('login')}
      </p>
      <form
        onSubmit={handleSignIn}
        className="flex w-full flex-col items-center justify-center gap-3"
      >
        <Input
          type="email"
          value={logins.email}
          onChange={setEmail}
          placeholder={t('email')}
        />
        <Input
          type="password"
          value={logins.password}
          onChange={setPassword}
          placeholder={t('password')}
        />
        <button
          type="submit"
          className="font-md w-full rounded bg-light-accent-2 py-3 text-light-primary-0 duration-200 hover:bg-light-accent-3 dark:bg-dark-accent-2 dark:hover:bg-dark-accent-3"
        >
          {t('login')}
        </button>
        <Link to={'/auth/signup'} className="flex flex-row gap-2 text-sm">
          <p className="text-light-secondary-1 dark:text-dark-secondary-1">
            {t('noAccount?')}
          </p>
          <p className="text-light-accent-2 duration-200 hover:text-light-accent-3 dark:text-dark-accent-2 dark:hover:text-dark-accent-3">
            {t('signup')}
          </p>
        </Link>
      </form>
      <div className="flex w-full flex-col items-center justify-center gap-3">
        <DividerWithText text={t('or')} />
        <button
          className="flex w-full flex-row items-center gap-4 rounded border border-light-secondary-4 p-4 py-3 text-left text-light-secondary-1 duration-200 hover:bg-light-primary-2 dark:border-dark-secondary-4 dark:text-dark-secondary-1 dark:hover:bg-dark-primary-2"
          onClick={signInGoogle}
        >
          <img className="h-5 w-5" src={googleLogo} alt="Google" />
          <p>{t('loginWithGoogle')}</p>
        </button>
      </div>
    </div>
  )
}

export default Login
