import { useDocument } from 'react-firebase-hooks/firestore'
import {
  GroupDataType,
  InvitedMember,
  UserDataType,
  UserGroupContext,
} from '../../utils/functions/types'
import { DocumentData, getDoc, doc } from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import { getUserGroupContext } from '../../utils/functions/firebase/user'
import { db } from '../../utils/functions/firebase/auth'

type Props = {
  groupId: string
  user: UserDataType
}

type PropsReturn = [
  group: GroupDataType,
  owner: UserDataType,
  members: UserDataType[],
  invitedMembers: InvitedMember[],
  userGroupContext: UserGroupContext,
]

export const useGroup = ({ groupId, user }: Props): PropsReturn => {
  const [groupData] = useDocument(doc(db, 'groups', groupId))
  const [group, setGroup] = useState<GroupDataType>()
  const [owner, setOwner] = useState<UserDataType>()
  const [members, setMembers] = useState<UserDataType[]>([])
  const [invitedMembers, setInvitedMembers] = useState<
    {
      user: UserDataType
      invitationId: string
    }[]
  >([])
  const [userGroupContext, setUserGroupContext] = useState<UserGroupContext>()

  const mapGroupData = (group: DocumentData): GroupDataType => {
    let g = group.data() as GroupDataType
    g.id = group.id
    return g
  }
  const sortMembers = useCallback(
    (m: UserDataType[], g: GroupDataType): UserDataType[] => {
      return m.sort((a, b) => {
        if (a.id === g.owner) return -1
        if (b.id === g.owner) return 1
        if (g.admins.includes(a.id)) return -1
        else if (g.admins.includes(b.id)) return 1
        else return 0
      })
    },
    []
  )
  const fetchMembers = useCallback(async (g: GroupDataType) => {
    let m = []
    let i = []
    for (const memberId of g.members) {
      const memberDoc = await getDoc(doc(db, 'users', memberId))
      let member = memberDoc.data() as UserDataType
      member.id = memberId
      if (memberId === g.owner) setOwner(member)
      m.push(member)
    }
    for (const invitedMember of g.invitations) {
      const invitedDoc = await getDoc(doc(db, 'users', invitedMember.userId))
      let member = invitedDoc.data() as UserDataType
      member.id = invitedDoc.id
      i.push({
        user: member,
        invitationId: invitedMember.invitationId,
      })
    }
    return {
      members: m,
      invitations: i,
    }
  }, [])

  useEffect(() => {
    if (groupData && user) {
      let g = mapGroupData(groupData)
      fetchMembers(g).then(({ members: m, invitations: i }) => {
        setMembers(sortMembers(m, g))
        setInvitedMembers(i)
      })
      setGroup(g)
      setUserGroupContext(getUserGroupContext(user, g))
    }
  }, [groupData, fetchMembers, sortMembers, user])
  return [group, owner, members, invitedMembers, userGroupContext]
}
