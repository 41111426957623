import { KeyboardEvent, MouseEvent, useRef, useState } from 'react'
import Input from '../../utils/components/Input'
import { MdCancel, MdEditSquare, MdError } from 'react-icons/md'
import IconButton from '../../utils/components/IconButton'
import { useTranslation } from 'react-i18next'
import BareDisplayEditField from './BareDisplayEditField'
import { StatusMessage } from '../../utils/functions/types'

type Props = {
  type: 'text' | 'password' | 'email'
  baseValue: string
  fieldName: string
  fieldValue: string
  setFieldValue: any
  handleModification: (args: any) => Promise<void>
  setStatusMessage: (status: StatusMessage) => void
}

function DisplayEditField({
  type,
  baseValue,
  fieldName,
  fieldValue,
  setFieldValue,
  handleModification,
  setStatusMessage,
}: Readonly<Props>) {
  const [disabled, setDisabled] = useState(true)
  const ref = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const toggleEdit = () => {
    if (disabled) {
      setDisabled(false)
      setTimeout(() => {
        ref.current?.focus()
        ref.current?.select()
      }, 100)
    } else {
      ref.current?.focus()
      ref.current?.blur()
      window.getSelection()?.removeAllRanges()
      setDisabled(true)
      setFieldValue(baseValue)
    }
  }
  const handleModificationWrapper = (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault()
    if (!fieldValue || fieldValue === '') {
      setStatusMessage({
        message: [t('fieldEmpty')],
        type: 'error',
      })
      return
    }
    if (fieldValue !== baseValue) {
      handleModification(fieldValue)
    }
    ref.current?.focus()
    ref.current?.blur()
    setDisabled(true)
  }
  const handleDisabledClick = (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault()
    toggleEdit()
  }

  const handleDisableKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (disabled) toggleEdit()
      else handleModificationWrapper(e)
    }
  }

  return (
    <BareDisplayEditField fieldName={fieldName}>
      <form
        onDoubleClick={(e) => handleDisabledClick(e)}
        onKeyDown={(key) => handleDisableKeyDown(key)}
        className="flex w-full flex-row items-center gap-4 sm:w-[36rem]"
      >
        <Input
          ref={ref}
          type={type === 'password' && disabled ? 'text' : type}
          value={fieldValue || ''}
          onChange={setFieldValue}
          placeholder={fieldName}
          fontSize={['lg', 'xl', '2xl']}
          noLabel
          disabled={disabled}
          important
          sideAction={
            <div className="flex flex-row items-center">
              {(!fieldValue || fieldValue === '') && type !== 'password' && (
                <div className="relative">
                  <div className="pointer-events-none absolute left-1/4 top-1/4 h-1/2 w-1/2 animate-ping rounded-full bg-light-accent-2 dark:bg-dark-accent-2"></div>
                  <div className="pointer-events-none absolute left-1/4 top-1/4 h-1/2 w-1/2 rounded-full bg-light-primary-2 dark:bg-dark-primary-2"></div>
                  <IconButton
                    icon={<MdError />}
                    onClick={() => {}}
                    fontSize="2rem"
                    forceIconClass="text-light-accent-2 dark:text-dark-accent-2"
                    tooltip={{
                      text: t('fieldRequired'),
                      distance: '10%',
                    }}
                  />
                </div>
              )}
              {disabled ? (
                <IconButton
                  icon={<MdEditSquare />}
                  onClick={toggleEdit}
                  fontSize="2rem"
                  tooltip={{
                    text: t('edit'),
                    distance: '10%',
                  }}
                />
              ) : (
                <IconButton
                  icon={<MdCancel />}
                  type="button"
                  onClick={toggleEdit}
                  fontSize="2rem"
                  forceIconClass="text-light-accent-2 dark:text-dark-accent-2"
                  tooltip={{
                    text: t('cancel'),
                    distance: '10%',
                  }}
                />
              )}
            </div>
          }
        />
        {!disabled && (
          <button
            type="submit"
            className="rounded-md bg-light-accent-2 px-4 py-2 font-medium text-light-primary-0 duration-200 hover:bg-light-accent-3 dark:bg-dark-accent-2 dark:text-light-primary-0 dark:hover:bg-dark-accent-1"
            onClick={(e) => handleModificationWrapper(e)}
          >
            {t('save')}
          </button>
        )}
      </form>
    </BareDisplayEditField>
  )
}

export default DisplayEditField
