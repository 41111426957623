import { useEffect, useRef } from 'react'

type Props = {
  id: string
  selected: boolean
  text: string
  onClick: any
}

function DropdownCountriesItem({
  id,
  selected,
  text,
  onClick,
}: Readonly<Props>) {
  const containerRef = useRef(null)
  useEffect(() => {
    if (selected && containerRef.current)
      containerRef.current.scrollIntoView({
        block: 'center',
        inline: 'center',
      })
  }, [selected])
  return (
    <li
      ref={containerRef}
      key={id}
      id={id}
      className={`mr-6 cursor-pointer list-none rounded px-2 hover:bg-light-primary-3 dark:hover:bg-dark-primary-3 ${
        selected
          ? 'text-light-accent-3 dark:text-dark-accent-3'
          : 'text-light-secondary-3 dark:text-dark-secondary-3'
      } whitespace-nowrap text-base font-medium capitalize duration-100`}
      onClick={() => onClick(id)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onClick(id)
      }}
    >
      <p className="truncate">{text}</p>
    </li>
  )
}

export default DropdownCountriesItem
