import { useCallback } from 'react'
import { MdOutlineLanguage } from 'react-icons/md'
import { languageCode } from '../../utils/functions/types'
import useComponentVisible from '../../utils/hooks/useComponentVisible'
import Dropdown from '../../utils/components/dropdown/Dropdown'
import DropdownItem from '../../utils/components/dropdown/DropdownItem'
import DropdownGroup from '../../utils/components/dropdown/DropdownGroup'
import IconButton from '../../utils/components/IconButton'

type Props = {
  languageSelected: languageCode
  setLanguageSelected: (language: languageCode) => void
  upward?: boolean
  fontSize?: string
  dropdownXOffSet?: string
  dropdownYOffSet?: string
}

function LanguageSelector({
  languageSelected,
  setLanguageSelected,
  upward,
  fontSize,
  dropdownXOffSet,
  dropdownYOffSet,
}: Readonly<Props>) {
  const [ref, dropdown, setDropdown] = useComponentVisible(false)
  const handleDropdownClick = useCallback(() => {
    if (dropdown) {
      setDropdown(false)
    } else {
      setDropdown(true)
    }
  }, [dropdown, setDropdown])
  const setLanguageEN = useCallback(() => {
    setLanguageSelected('en')
    setDropdown(false)
  }, [setLanguageSelected, setDropdown])
  const setLanguageFR = useCallback(() => {
    setLanguageSelected('fr')
    setDropdown(false)
  }, [setLanguageSelected, setDropdown])
  return (
    <div ref={ref} className="relative flex flex-col items-center">
      <IconButton
        icon={<MdOutlineLanguage />}
        onClick={handleDropdownClick}
        fontSize={fontSize}
      />
      {dropdown && (
        <Dropdown
          containerRef={ref}
          upward={upward}
          xOffSet={dropdownXOffSet || '0px'}
          yOffSet={dropdownYOffSet || (upward ? '-' : '') + '6px'}
        >
          <DropdownGroup>
            <DropdownItem
              active={languageSelected === 'en'}
              onClick={setLanguageEN}
            >
              <div className="text-center">English</div>
            </DropdownItem>
            <DropdownItem
              active={languageSelected === 'fr'}
              onClick={setLanguageFR}
            >
              <div className="text-center">Français</div>
            </DropdownItem>
          </DropdownGroup>
        </Dropdown>
      )}
    </div>
  )
}

export default LanguageSelector
