import { useCallback, useEffect, useState } from 'react'
import {
  StatusMessage,
  UserDataType,
  UserFunctions,
} from '../../utils/functions/types'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  deleteAccount,
  modifyUserEmail,
  modifyUserFirstName,
  modifyUserName,
  modifyUserPassword,
  modifyUserPhone,
  requestUserData,
} from '../../utils/functions/firebase/userManagement'
import { useLocalStorage } from '@uidotdev/usehooks'

export const useUserFunctions = (
  userData: UserDataType,
  openConfirmation: (question: string, action: () => Promise<void>) => void,
  openStatusMessage: (statusMessage: StatusMessage) => void
): UserFunctions => {
  const [userFunctions, setUserFunctions] = useState<UserFunctions>()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [, setAccoutDeletion] = useLocalStorage('accountDeletion')

  const modifyFirstNameHandler = useCallback(
    async (firstName: string) => {
      await modifyUserFirstName(firstName, openStatusMessage)
      openStatusMessage({
        message: [t('firstNameModified')],
        type: 'success',
      })
    },
    [openStatusMessage, t]
  )

  const modifyNameHandler = useCallback(
    async (lastName: string) => {
      await modifyUserName(lastName, openStatusMessage)
      openStatusMessage({
        message: [t('lastNameModified')],
        type: 'success',
      })
    },
    [openStatusMessage, t]
  )

  const modifyPasswordHandler = useCallback(
    async (password: string) => {
      await modifyUserPassword(password, openStatusMessage)
      openStatusMessage({
        message: [t('lastNameModified')],
        type: 'success',
      })
    },
    [openStatusMessage, t]
  )

  const modifyEmailHandler = useCallback(
    async (email: string) => {
      await modifyUserEmail(email, openStatusMessage)
      openStatusMessage({
        message: [t('emailModified')],
        type: 'success',
      })
    },
    [openStatusMessage, t]
  )

  const modifyPhoneHandler = useCallback(
    async (phone: string) => {
      await modifyUserPhone(phone, openStatusMessage)
      openStatusMessage({
        message: [t('phoneModified')],
        type: 'success',
      })
    },
    [openStatusMessage, t]
  )

  const requestDataHandler = useCallback(async () => {
    const data = await requestUserData(openStatusMessage)
    const a = document.createElement('a')
    a.href = URL.createObjectURL(
      new Blob([JSON.stringify(data, null, 3)], { type: 'text/json' })
    )
    a.download = t('userDataFileName')
    a.click()
  }, [openStatusMessage, t])

  const deleteAccountHandler = useCallback(() => {
    openConfirmation(t('deleteAccount?'), async () => {
      setAccoutDeletion(true)
      navigate('/account-deletion')
      await deleteAccount(openStatusMessage)
      setAccoutDeletion(false)
    })
  }, [openConfirmation, t, setAccoutDeletion, navigate, openStatusMessage])

  useEffect(() => {
    if (userData) {
      const userFunctions: UserFunctions = {
        modifyFirstName: modifyFirstNameHandler,
        modifyName: modifyNameHandler,
        modifyEmail: modifyEmailHandler,
        modifyPassword: modifyPasswordHandler,
        modifyPhone: modifyPhoneHandler,
        requestData: requestDataHandler,
        deleteAccount: deleteAccountHandler,
      }
      setUserFunctions(userFunctions)
    }
  }, [
    openConfirmation,
    openStatusMessage,
    t,
    location,
    navigate,
    userData,
    deleteAccountHandler,
    modifyFirstNameHandler,
    modifyNameHandler,
    modifyEmailHandler,
    modifyPasswordHandler,
    modifyPhoneHandler,
    requestDataHandler,
  ])
  return userFunctions
}
