import {
  GroupCardTab,
  GroupDataType,
  UserDataType,
} from '../../utils/functions/types'
import { useTranslation } from 'react-i18next'

type Props = {
  setTab: (tab: GroupCardTab) => void
  group: GroupDataType
  owner: UserDataType
  members: UserDataType[]
}

function GroupTabSummary({ setTab, group, owner, members }: Readonly<Props>) {
  const { t } = useTranslation()
  const admins = members.filter(
    (member) => group.admins.includes(member.id) && member.id !== owner.id
  )

  return (
    <div
      className="h-full w-full rounded-lg p-8"
      id="about"
      role="tabpanel"
      aria-labelledby="about-tab"
    >
      <h2 className="line-clamp-1 text-xl font-semibold tracking-tight text-light-secondary-1 dark:text-dark-secondary-1 sm:text-3xl">
        {group.name}
      </h2>
      <p className="line-clamp-2 h-12 text-light-secondary-4 dark:text-dark-secondary-4">
        {group.description}
      </p>
      <p className="text-sm font-medium text-light-secondary-4 dark:text-dark-secondary-4">
        {t(group.status)}
      </p>
      {owner && (
        <p className="text-sm font-medium text-light-secondary-4 dark:text-dark-secondary-4">
          {t('owner') + ' : '}
          <span className="cursor-pointer text-sm font-medium text-light-secondary-4 hover:text-light-accent-2 dark:text-dark-secondary-4 dark:hover:text-dark-accent-2">
            {owner.firstName} {owner.name}
          </span>
        </p>
      )}
      <button
        className="w-max cursor-pointer text-sm font-medium text-light-secondary-4 hover:text-light-accent-2 dark:text-dark-secondary-4 dark:hover:text-dark-accent-2"
        onClick={() => setTab(1)}
      >
        {group.members.length + ' '}
        {group.members.length > 1 ? t('members') : t('member')}
      </button>
      {admins && admins.length > 0 && (
        <div className="flex flex-row flex-wrap gap-1 text-sm font-medium text-light-secondary-4 dark:text-dark-secondary-4">
          <p>{group.admins.length > 1 ? t('admins') : t('admin') + ' : '}</p>
          {admins.map((admin, index) => {
            return (
              <p
                key={admin.id}
                className="cursor-pointer text-sm font-medium text-light-secondary-4 hover:text-light-accent-2 dark:text-dark-secondary-4 dark:hover:text-dark-accent-2"
              >
                {admin.firstName} {admin.name}
                {index < admins.length - 1 && ', '}
              </p>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default GroupTabSummary
