import { updateDoc, doc, deleteDoc } from 'firebase/firestore'
import { auth, db } from './auth'
import { StatusMessage, GroupDataType, NotificationsType } from '../types'
import { deleteUser, updateEmail, updatePassword } from 'firebase/auth'
import { getGroup } from './group'
import { leaveGroup } from './groupManagement'
import {
  getUserInvitations,
  cancelInvitation,
  getUserNotifications,
} from './notifications'
import { getUser } from './user'

export const modifyUserFirstName = async (
  firstName: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    await updateDoc(doc(db, 'users', auth.currentUser.uid), {
      firstName: firstName,
    })
  } catch (error: any) {
    console.log('error while modifying first name : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  } finally {
    openStatusMessage({ message: ['firstNameModified'], type: 'success' })
  }
}

export const modifyUserName = async (
  name: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    await updateDoc(doc(db, 'users', auth.currentUser.uid), {
      name: name,
    })
  } catch (error: any) {
    console.log('error while modifying name : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  } finally {
    openStatusMessage({ message: ['nameModified'], type: 'success' })
  }
}

export const modifyUserPhone = async (
  phone: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    await updateDoc(doc(db, 'users', auth.currentUser.uid), {
      phone: phone,
    })
  } catch (error: any) {
    console.log('error while modifying phone : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  } finally {
    openStatusMessage({ message: ['phoneModified'], type: 'success' })
  }
}

export const modifyUserEmail = async (
  email: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  // change email in authentication
  try {
    await updateEmail(auth.currentUser, email)
  } catch (error: any) {
    console.log('error while modifying email : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  }
  try {
    await updateDoc(doc(db, 'users', auth.currentUser.uid), {
      email: email,
    })
  } catch (error: any) {
    console.log('error while modifying email : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  } finally {
    openStatusMessage({ message: ['emailModified'], type: 'success' })
  }
}

export const modifyUserPassword = async (
  password: string,
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  try {
    await updatePassword(auth.currentUser, password)
  } catch (error: any) {
    if (error.code === 'auth/requires-recent-login') {
      openStatusMessage({ message: ['requiresRecentLogin'], type: 'error' })
      return
    }
    console.log('error while modifying password : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  } finally {
    openStatusMessage({ message: ['passwordModified'], type: 'success' })
  }
}

export const deleteAccount = async (
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<void> => {
  if (auth.currentUser) {
    try {
      const userData = await getUser(auth.currentUser.uid)
      // leave all groups
      try {
        for (const group of userData.groups) {
          const groupData: GroupDataType = await getGroup(group)
          await leaveGroup(groupData, openStatusMessage)
        }
      } catch (error: any) {
        console.log('error while leaving groups : ' + error.message)
        openStatusMessage({ message: ['errorOccured'], type: 'error' })
      }
      // delete Invitations
      try {
        const invitations = await getUserInvitations(userData)
        for (const invitation of invitations) {
          await cancelInvitation(
            invitation.group,
            invitation.to,
            invitation.id,
            openStatusMessage
          )
        }
      } catch (error: any) {
        console.log('error while deleting invitations : ' + error.message)
        openStatusMessage({ message: ['errorOccured'], type: 'error' })
      }
      // delete Notifications
      try {
        await deleteDoc(doc(db, 'notifications', userData.notificationsId))
      } catch (error: any) {
        console.log('error while deleting notifications : ' + error.message)
        openStatusMessage({ message: ['errorOccured'], type: 'error' })
      }
      // delete user
      try {
        await deleteDoc(doc(db, 'users', auth.currentUser.uid))
      } catch (error: any) {
        console.log("error while deleting account's data : " + error.message)
        openStatusMessage({ message: ['errorOccured'], type: 'error' })
      }
      // delete authentication
      try {
        auth.currentUser.getIdToken()
        await deleteUser(auth.currentUser)
      } catch (error: any) {
        console.log(
          "error while deleting account's authentication : " + error.message
        )
        openStatusMessage({ message: ['errorOccured'], type: 'error' })
      }
    } finally {
      openStatusMessage({ message: ['accountDeleted'], type: 'success' })
    }
  }
}

export const changeUserLocale = async (locale: string): Promise<void> => {
  try {
    if (auth.currentUser) {
      await updateDoc(doc(db, 'users', auth.currentUser.uid), {
        locale: locale,
      })
    }
  } catch (error: any) {
    console.log('error while changing user locale : ' + error.message)
  }
}

export const requestUserData = async (
  openStatusMessage: (statusMessage: StatusMessage) => void
): Promise<JSON> => {
  let userData: any
  try {
    if (auth.currentUser) {
      userData = await getUser(auth.currentUser.uid)
      if (userData) {
        const notificationsData: NotificationsType =
          await getUserNotifications(userData)
        userData.notifications = notificationsData
        userData.notifications.id = userData.notificationsId
        delete userData.notificationsId
        for (const [index, group] of [...userData.groups.entries()]) {
          const groupData = await getGroup(group)
          if (groupData) {
            userData.groups[index] = groupData
          }
        }
        return JSON.parse(JSON.stringify(userData))
      } else {
        throw new Error('no user data found')
      }
    } else {
      throw new Error('no user connected')
    }
  } catch (error: any) {
    console.log('error while requesting user data : ' + error.message)
    openStatusMessage({ message: ['errorOccured'], type: 'error' })
  } finally {
    openStatusMessage({ message: ['userDataRequested'], type: 'success' })
  }
}
