import Popup from '../popup/Popup'

type Props = {
  children: React.ReactNode
  isOpened: boolean
  close: () => void
}

function Confirmation({ children, isOpened, close }: Readonly<Props>) {
  return (
    <Popup isOpened={isOpened} close={close}>
      {children}
    </Popup>
  )
}

export default Confirmation
