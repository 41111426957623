import { useCallback, useRef, useState } from 'react'
import { StatusMessage } from '../../utils/functions/types'

export const useStatusMessage = (
  initialState: StatusMessage
): [
  statusMessage: StatusMessage,
  openStatusMessage: (status: StatusMessage) => void
] => {
  const [message, setMessage] = useState(initialState.message)
  const [type, setType] = useState(initialState.type)
  const statusMessage: StatusMessage = { message, type }
  const statusTimeout = useRef(null)
  const openStatusMessage = useCallback(
    (status: StatusMessage): void => {
      setMessage(status.message)
      setType(status.type)
      if (statusTimeout.current) clearTimeout(statusTimeout.current)
      statusTimeout.current = setTimeout(() => {
        setType(null)
      }, 8000)
    },
    [setMessage, setType, statusTimeout]
  )
  return [statusMessage, openStatusMessage]
}
