import { MouseEventHandler } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { HiMenu } from 'react-icons/hi'
import LanguageSelector from '../shared/buttons/LanguageSelector'
import UserSettings from './dropdowns/UserSettings'
import { UserDataType, languageCode } from '../utils/functions/types'
import NavbarGroup from './NavbarGroup'
import { useTranslation } from 'react-i18next'
import Notifications from './dropdowns/notifications/Notifications'
import LoadingWidget from '../utils/components/loading/LoadingWidget'
import NotificationCount from './notification_count/NotificationCount'

type Props = {
  userData: UserDataType
  darkMode: boolean
  toggleDarkMode: () => void
  languageSelected: languageCode
  setLanguageSelected: (language: languageCode) => void
  mobileMenuHandler: MouseEventHandler<HTMLButtonElement>
}

function Navbar({
  userData,
  darkMode,
  toggleDarkMode,
  languageSelected,
  setLanguageSelected,
  mobileMenuHandler,
}: Readonly<Props>) {
  const { t } = useTranslation()
  const location = useLocation()

  const findName = () => {
    if (userData.firstName) return userData.firstName
    else {
      return userData.name ? userData.name : 'Anonyme'
    }
  }

  return (
    <header className="relative h-16 border-b border-light-primary-3 pr-[9px] duration-200 dark:border-dark-primary-3 xs:h-20">
      <div className="flex h-full items-center justify-between pt-2 md:items-end md:pt-0">
        <Link
          to="/"
          className="mb-2 w-min rounded-lg px-4 focus:outline-none focus:ring-2 focus:ring-light-primary-2 dark:focus:ring-dark-primary-2"
        >
          <p
            translate="no"
            className="notranslate whitespace-nowrap font-dancing text-4xl text-light-accent-2 dark:text-dark-accent-2 xs:text-5xl sm:text-5xl md:text-6xl"
          >
            SecretGifts
          </p>
        </Link>
        <ul className="hidden items-center justify-center gap-6 md:flex">
          {userData ? (
            <div className="hidden items-center justify-center gap-6 md:flex">
              <NavbarGroup active={location.pathname === '/profile'}>
                <Link to={'/profile'} className="cursor-pointer text-2xl ">
                  <p className="text-light-secondary-0 duration-200 hover:text-light-accent-2 dark:text-dark-secondary-0 dark:hover:text-dark-accent-2">
                    {findName()}
                  </p>
                </Link>
              </NavbarGroup>
              <NavbarGroup active={location.pathname === '/groups'}>
                <Link to={'/groups'} className="cursor-pointer text-2xl">
                  <p className="text-light-secondary-0 duration-200 hover:text-light-accent-2 dark:text-dark-secondary-0 dark:hover:text-dark-accent-2">
                    {t('selectGroup')}
                  </p>
                </Link>
              </NavbarGroup>
            </div>
          ) : (
            <p className="mb-2 text-2xl text-light-secondary-0 duration-200 dark:text-dark-secondary-0">
              {t('loading') + '...'}
            </p>
          )}
          <NavbarGroup last>
            <li className="flex flex-row items-center justify-center gap-2">
              {userData ? (
                <Notifications userData={userData} />
              ) : (
                <LoadingWidget size={'7'} />
              )}
              <LanguageSelector
                languageSelected={languageSelected}
                setLanguageSelected={setLanguageSelected}
              />
              <UserSettings
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
              />
            </li>
          </NavbarGroup>
        </ul>
        <button
          className="mb-1 inline-flex h-8 w-10 cursor-pointer items-center rounded-lg focus:outline-none focus:ring-2 focus:ring-light-primary-2 dark:focus:ring-dark-primary-2 sm:mb-0 md:hidden"
          onClick={mobileMenuHandler}
        >
          <p className="hidden px-1 text-3xl uppercase text-light-secondary-0 duration-200 dark:text-dark-secondary-0 sm:inline-block">
            {t('menu')}
          </p>
          <div
            style={{ fontSize: '2.5rem' }}
            className="relative pb-[4px] pr-[5px] pt-[5px] text-light-secondary-0 opacity-80 dark:text-dark-secondary-0"
          >
            <HiMenu />
            <NotificationCount data={userData} forceFullSize />
          </div>
        </button>
      </div>
    </header>
  )
}

export default Navbar
