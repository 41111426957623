import { MutableRefObject, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  MdClose,
  MdLogout,
  MdNightlightRound,
  MdSettings,
} from 'react-icons/md'
import { FaUser } from 'react-icons/fa'
import { FaUserGroup } from 'react-icons/fa6'
import LanguageSelector from '../../shared/buttons/LanguageSelector'
import { UserDataType, languageCode } from '../../utils/functions/types'
import MobileMenuLink from './MobileMenuLink'
import { useTranslation } from 'react-i18next'
import { logout } from '../../utils/functions/firebase/auth'
import CheckButton from '../../utils/components/CheckButton'
import Notifications from '../dropdowns/notifications/Notifications'
import IconButton from '../../utils/components/IconButton'
import { getFullName } from '../../utils/functions/firebase/user'

type Props = {
  userData: UserDataType
  darkMode: boolean
  toggleDarkMode: () => void
  languageSelected: languageCode
  setLanguageSelected: (language: languageCode) => void
  forwardedRef: MutableRefObject<any>
  closeMenuHandler: () => void
}

function MobileMenu({
  userData,
  darkMode,
  toggleDarkMode,
  languageSelected,
  setLanguageSelected,
  forwardedRef,
  closeMenuHandler,
}: Readonly<Props>) {
  const { t } = useTranslation()
  const location = useLocation()
  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    timeoutId = setTimeout(() => {
      forwardedRef.current.style.transform = 'translateX(0)'
      document.getElementById('overlay').style.opacity = '0.5'
    }, 10)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [forwardedRef])
  return (
    <div>
      <div
        className="fixed bottom-0 left-0 top-0 z-40 w-screen bg-black duration-200"
        onClick={closeMenuHandler}
        onKeyDown={(e) => e.key === 'Escape' && closeMenuHandler()}
        style={{ opacity: 0 }}
        id="overlay"
      ></div>
      <div
        className="fixed bottom-0 left-0 top-0 z-50 flex w-4/5 flex-col bg-light-primary-1 duration-200 dark:bg-dark-primary-1"
        style={{ transform: 'translate(-100%)' }}
        ref={forwardedRef}
      >
        <div className="flex w-full flex-row items-center justify-between p-2">
          <Link
            to="/"
            className="flex w-min flex-row items-center gap-2 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-light-primary-2 dark:focus:ring-dark-primary-2"
          >
            <p
              translate="no"
              className="notranslate whitespace-nowrap pl-1 font-dancing text-3xl text-light-accent-2 dark:text-dark-accent-2 xs:text-4xl"
            >
              SecretGifts
            </p>
          </Link>
          <ul className="flex flex-row items-center gap-1">
            <li>
              <Notifications userData={userData} xOffSet="-8%" />
            </li>
            <li className="relative flex flex-col items-center">
              <IconButton
                onClick={closeMenuHandler}
                icon={<MdClose />}
                fontSize="2.25rem"
              />
            </li>
          </ul>
        </div>
        <ul className="w-full p-4">
          <li className="flex flex-row items-center">
            <div className="mr-2 h-px w-full bg-light-primary-3 dark:bg-dark-primary-3"></div>
            <p className="whitespace-nowrap px-2 text-light-secondary-4 dark:text-dark-secondary-4">
              {getFullName(userData)}
            </p>
          </li>
          <MobileMenuLink
            icon={<FaUser />}
            link="/profile"
            active={location.pathname === '/profile'}
            onClick={closeMenuHandler}
          >
            {t('profile')}
          </MobileMenuLink>
          <MobileMenuLink
            icon={<FaUserGroup />}
            link="/groups"
            active={location.pathname === '/groups'}
            onClick={closeMenuHandler}
          >
            {t('groups')}
          </MobileMenuLink>
          <MobileMenuLink
            icon={
              <div className="mt-px -rotate-[30deg] scale-110">
                <MdNightlightRound />
              </div>
            }
            onClick={toggleDarkMode}
            sideAction={
              <CheckButton checked={darkMode} onChange={toggleDarkMode} />
            }
          >
            {t('darkTheme')}
          </MobileMenuLink>
        </ul>
        <div className="absolute bottom-0 left-0 flex w-full flex-row items-center justify-between p-2">
          <div className="flex flex-row items-center gap-1">
            <IconButton
              to="/settings"
              icon={<MdSettings />}
              active={location.pathname !== '/settings'}
              onClick={closeMenuHandler}
              fontSize="2.25rem"
            />
            <LanguageSelector
              languageSelected={languageSelected}
              setLanguageSelected={setLanguageSelected}
              upward
              fontSize="2.25rem"
            />
          </div>
          <div className="flex flex-row items-center gap-1">
            <IconButton
              onClick={logout}
              icon={<MdLogout />}
              fontSize="2.25rem"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
