type Props = {
  children: React.ReactNode
}

function Banner({ children }: Readonly<Props>) {
  return (
    <div
      className={
        'my-2 border-b-2 border-t-2 border-light-accent-2 p-2 text-center text-xl text-light-secondary-0 dark:border-dark-accent-2 dark:text-dark-secondary-0'
      }
    >
      {children}
    </div>
  )
}

export default Banner
