import { ReactNode } from 'react'

type Props = {
  name?: string
  first?: boolean
  children: ReactNode
}

function DropdownGroup({ name, first, children }: Readonly<Props>) {
  return (
    <div>
      {!name ? (
        !first && (
          <div className="mx-3 mb-[6px] mt-1 h-px bg-light-primary-3 dark:bg-dark-primary-3"></div>
        )
      ) : (
        <div className="flex flex-row items-center">
          <p className="px-2 text-light-secondary-4 dark:text-dark-secondary-4">
            {name}
          </p>
          <div className="my-1 ml-2 mr-3 h-px w-full bg-light-primary-3 dark:bg-dark-primary-3"></div>
        </div>
      )}
      <ul className="flex flex-col">{children}</ul>
    </div>
  )
}

export default DropdownGroup
