function DividerWithText({ text }: Readonly<{ text: string }>) {
  return (
    <div className="mt-2 flex w-full flex-row items-center justify-start gap-4">
      <div className="h-px w-full bg-light-primary-4 dark:bg-dark-primary-4"></div>
      <div className="whitespace-nowrap font-light uppercase text-light-secondary-3 dark:text-dark-secondary-3">
        {text}
      </div>
      <div className="h-px w-full bg-light-primary-4 dark:bg-dark-primary-4"></div>
    </div>
  )
}

export default DividerWithText
