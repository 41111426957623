type Props = {
  checked: boolean
  onChange: () => void
}

function CheckButton({ checked, onChange }: Readonly<Props>) {
  return (
    <div className="flex flex-row items-center">
      <label className="relative mx-2 inline-flex cursor-pointer items-center">
        <input
          id="switch-2"
          type="checkbox"
          className="peer sr-only"
          checked={checked}
          onChange={onChange}
        />
        <label className="hidden"></label>
        <div className="peer h-3 w-8 rounded-full border bg-light-primary-3 after:absolute after:-left-[2px] after:-top-[3px] after:h-[18px] after:w-[18px] after:rounded-full after:border after:border-light-primary-3 after:bg-light-primary-1 after:transition-all after:content-[''] peer-checked:bg-dark-primary-3 peer-checked:after:translate-x-full dark:bg-dark-primary-3"></div>
      </label>
    </div>
  )
}

export default CheckButton
