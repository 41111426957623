import React, { Children, ReactElement, ReactNode, cloneElement } from 'react'

type Props = {
  containerRef: React.RefObject<HTMLElement>
  children: ReactNode
  upward?: boolean
  xOffSet?: string
  yOffSet?: string
}

function Dropdown({ containerRef, children, upward, xOffSet, yOffSet }: Props) {
  children = Children.toArray(children)
  const containerSize = getContainerSize(containerRef)
  const anchoringContent = upward
    ? { bottom: `calc(${containerSize.height}px + 4px)` }
    : { top: '0px' }
  const arrowSize = '16px'
  const anchoringArrow = upward
    ? {
        top: `calc(-${containerSize.height}px - ${arrowSize} + ${yOffSet} + 4px)`,
      }
    : { bottom: `calc(-${arrowSize} - ${yOffSet} + 4px)` }
  return (
    containerSize &&
    children && (
      <div className="relative z-20">
        <div
          className="absolute right-1/2"
          style={{
            ...anchoringContent,
            transform: `translate(${getXOffset(xOffSet)}, ${yOffSet || '0px'}`,
          }}
        >
          <div className="mt-1 rounded border border-light-primary-2 bg-light-primary-1 py-4 shadow-xl dark:border-dark-primary-2 dark:bg-dark-primary-1">
            <ul className="flex max-h-52 w-max min-w-[128px] max-w-[300px] flex-col justify-start gap-1 overflow-y-auto overflow-x-hidden px-2 scrollbar-thin scrollbar-track-light-primary-2 scrollbar-thumb-light-primary-4 scrollbar-thumb-rounded-sm dark:scrollbar-track-dark-primary-2 dark:scrollbar-thumb-dark-primary-4 md:max-w-[400px]">
              {Children.map(children, (child, index) => {
                if (child) {
                  if (index === 0) {
                    return cloneElement(child as ReactElement, {
                      first: true,
                    })
                  } else return <li>{child}</li>
                }
              })}
            </ul>
          </div>
        </div>
        <div
          className={
            'absolute left-1/2 z-10 -translate-x-1/2 rotate-45 border-light-primary-2 bg-light-primary-1 dark:border-dark-primary-2 dark:bg-dark-primary-1' +
            (upward ? ' border-b border-r' : ' border-l border-t')
          }
          style={{ ...anchoringArrow, height: arrowSize, width: arrowSize }}
        ></div>
      </div>
    )
  )
}

const getContainerSize = (
  containerRef: React.RefObject<HTMLElement>
): { width: number; height: number } => {
  if (containerRef) {
    const container = containerRef.current
    if (container) {
      const containerRect = container.getBoundingClientRect()
      return {
        width: containerRect.width,
        height: containerRect.height,
      }
    }
  }
  return undefined
}

const getXOffset = (xOffSet: string) => {
  if (xOffSet) {
    let xOffSetValue: string = null
    let xOffsetNegative: boolean = xOffSet.startsWith('-')
    if (xOffSet.endsWith('%')) {
      if (xOffsetNegative) {
        xOffSetValue = (50 - Number(xOffSet.slice(1, -1))).toString() + '%'
      } else {
        xOffSetValue = (50 + Number(xOffSet.slice(0, -1))).toString() + '%'
      }
    } else if (xOffsetNegative) {
      xOffSetValue = 'calc(50% - ' + xOffSet.slice(1) + ')'
    } else {
      xOffSetValue = 'calc(50% + ' + xOffSet + ')'
    }
    return xOffSetValue
  } else {
    return '50%'
  }
}

export default Dropdown
