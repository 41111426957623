import { Auth, User, onAuthStateChanged } from 'firebase/auth'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { useStatusMessage } from '../utils/hooks/useStatusMessage'
import { useLogins } from './hooks/useLogins'
import { languageCode } from '../utils/functions/types'
import Login from './Login'
import Signup from './Signup'
import { useInfos } from './hooks/useInfos'
import Status from '../utils/components/Status'
import { logo } from '../../ressources/media/images'
import { useEffect, useState } from 'react'
import {
  createUserMail,
  signInEmail,
  signInGoogle,
} from '../utils/functions/firebase/auth'
import LanguageSelector from '../shared/buttons/LanguageSelector'
import { useTranslation } from 'react-i18next'
import IconButton from '../utils/components/IconButton'
import { MdOutlineDarkMode } from 'react-icons/md'

type Props = {
  auth: Auth
  toggleDarkMode: () => void
}

function Authentification({ auth, toggleDarkMode }: Readonly<Props>) {
  const navigate = useNavigate()
  const location = useLocation()
  const { i18n } = useTranslation()
  const [languageSelected, setLanguageSelected] = useState(
    i18n.language as languageCode
  )
  const [logins, setEmail, setPassword, resetLogins] = useLogins()
  const [infos, setName, setFirstName, setPhone, resetInfos] = useInfos()
  const [statusMessage, openStatusMessage] = useStatusMessage({
    message: [''],
    type: null,
  })
  useEffect(() => {
    const handleAuthChange = (user: User) => {
      if (
        user &&
        (location.pathname === '/auth/login' ||
          location.pathname === '/auth/signup')
      )
        navigate('/')
    }
    onAuthStateChanged(auth, (user) => {
      handleAuthChange(user)
    })
  }, [auth, location.pathname, navigate])

  const resetCredentials = () => {
    resetInfos()
    resetLogins()
  }

  const handleLanguageChange = (language: languageCode) => {
    i18n.changeLanguage(language)
    setLanguageSelected(language)
  }

  return (
    <div className="mx-auto mb-12 flex w-full flex-1 flex-col justify-center gap-2 px-10 sm:mb-24 sm:w-[400px]">
      <div className="absolute right-8 top-8 flex md:gap-2">
        <IconButton
          icon={<MdOutlineDarkMode />}
          onClick={toggleDarkMode}
          fontSize="2rem"
        />
        <LanguageSelector
          languageSelected={languageSelected}
          setLanguageSelected={handleLanguageChange}
          dropdownXOffSet="-20%"
          fontSize="2rem"
        />
      </div>
      <div className="flex flex-col items-center">
        <img src={logo} alt="SecretGifts logo" className="h-24 sm:h-28" />
        <p
          translate="no"
          className="notranslate font-dancing text-7xl text-light-accent-2 dark:text-dark-accent-2 sm:text-8xl"
        >
          SecretGifts
        </p>
      </div>
      <Status statusMessage={statusMessage} />
      <Routes>
        <Route
          path="/login"
          element={
            <Login
              logins={logins}
              setEmail={setEmail}
              setPassword={setPassword}
              signInEmail={() => signInEmail(logins, openStatusMessage)}
              signInGoogle={() => signInGoogle(openStatusMessage)}
              openStatusMessage={openStatusMessage}
            />
          }
        ></Route>
        <Route
          path="/signup"
          element={
            <Signup
              logins={logins}
              infos={infos}
              setEmail={setEmail}
              setPassword={setPassword}
              setName={setName}
              setFirstName={setFirstName}
              setPhone={setPhone}
              handleCreateUser={() =>
                createUserMail(
                  infos,
                  logins,
                  openStatusMessage,
                  resetCredentials
                )
              }
              signInGoogle={() => signInGoogle(openStatusMessage)}
              openStatusMessage={openStatusMessage}
            />
          }
        ></Route>
      </Routes>
    </div>
  )
}

export default Authentification
