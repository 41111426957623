import { MdAccountCircle } from 'react-icons/md'
import { UserDataType } from '../../utils/functions/types'
import { FaCrown } from 'react-icons/fa'

type Props = {
  member: UserDataType
  owner?: boolean
}

function GroupTabMembersItem({ member, owner }: Readonly<Props>) {
  return (
    <div className="hover:border-light-accent-7 group relative flex h-24 w-full min-w-[80px] max-w-[132px] cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-light-primary-3 bg-light-primary-1 p-2 duration-200 hover:bg-light-accent-5 hover:text-light-accent-2 dark:border-dark-primary-3 dark:bg-dark-primary-1 dark:hover:border-dark-accent-1 dark:hover:bg-dark-accent-4 dark:hover:text-dark-accent-2">
      {owner && (
        <div className="absolute left-1 top-0 h-4 w-4">
          <div
            style={{ fontSize: '1rem' }}
            className="text-yellow-400 dark:text-yellow-500"
          >
            <FaCrown />
          </div>
        </div>
      )}
      <div
        style={{ fontSize: '2rem' }}
        className="text-light-accent-2 dark:text-dark-accent-2"
      >
        <MdAccountCircle />
      </div>
      <div className="w-full">
        <p className="line-clamp-2 w-full text-center leading-none text-light-secondary-0 dark:text-dark-secondary-0">
          {member.firstName} {member.name}
        </p>
      </div>
    </div>
  )
}

export default GroupTabMembersItem
