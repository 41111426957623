import {
  query,
  collection,
  where,
  getDocs,
  QueryDocumentSnapshot,
  DocumentData,
  getDoc,
  doc,
} from 'firebase/firestore'
import { GroupDataType, UserDataType, UserGroupContext } from '../types'
import { db } from './auth'

export const getUser = async (userId: string): Promise<UserDataType> => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId))
    if (userDoc.exists()) {
      return getUserDataFromDoc(userDoc)
    } else {
      return null
    }
  } catch (error: any) {
    console.log('error while getting user : ' + error.message)
    return null
  }
}

export const getUserFromEmail = async (
  email: string
): Promise<UserDataType> => {
  try {
    const q = query(collection(db, 'users'), where('email', '==', email))
    let userDoc = await getDocs(q)
    if (userDoc.empty) {
      return null
    }
    return getUserDataFromDoc(userDoc.docs[0])
  } catch (error: any) {
    console.log('error while finding user : ' + error.message)
    return null
  }
}

export const getUserGroupContext = (
  user: UserDataType,
  group: GroupDataType
): UserGroupContext => {
  const owner = group.owner === user.id
  const admin = owner || group.admins.includes(user.id)
  const member = group.members.includes(user.id)
  const invited =
    group.invitations.find((invitation) => invitation.userId === user.id) !==
    undefined
  return {
    ...user,
    owner,
    admin,
    member,
    invited,
  }
}

export const getUserDataFromDoc = (
  userDoc: QueryDocumentSnapshot<DocumentData>
): UserDataType => {
  const userData = userDoc.data() as UserDataType
  userData.id = userDoc.id
  return userData
}

export const getFullName = (user: UserDataType): string => {
  return user.name.length > 0
    ? user.firstName + ' ' + user.name
    : user.firstName
}
