import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  onClick: () => void
}

function MediumButton({ children, onClick }: Readonly<Props>) {
  return (
    <button
      className="flex w-full items-center justify-center rounded-lg border border-light-primary-3 bg-light-primary-2 p-8 text-center text-light-secondary-1 duration-200 hover:bg-light-primary-3 focus:outline-none focus:ring-4 focus:ring-light-primary-2 dark:border-dark-primary-3 dark:bg-dark-primary-2 dark:text-dark-secondary-1 dark:hover:bg-dark-primary-3 dark:focus:ring-dark-primary-2 md:p-4"
      onClick={onClick}
    >
      <p className="text-lg font-medium"> {children}</p>
    </button>
  )
}

export default MediumButton
