import { UserDataType } from '../../utils/functions/types'
import NotificationCountFromUserData from './NotificationCountFromUserData'
import NotificationCountWidget from './NotificationCountWidget'

type Props = {
  data: UserDataType | number
  forceFullSize?: boolean
}

function NotificationCount({ data, forceFullSize }: Props) {
  return (
    data !== undefined &&
    (typeof data === 'number' ? (
      <NotificationCountWidget
        unseenCount={data}
        forceFullSize={forceFullSize}
      />
    ) : (
      <NotificationCountFromUserData
        userData={data}
        forceFullSize={forceFullSize}
      />
    ))
  )
}

export default NotificationCount
