import { useCallback, useEffect, useRef, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router'
import { User, onAuthStateChanged } from 'firebase/auth'
import Navbar from '../navbar/Navbar'
import MobileMenu from '../navbar/mobile_menu/MobileMenu'
import Home from '../home/Home'
import Settings from '../settings/Settings'
import { auth } from '../utils/functions/firebase/auth'
import { useUserData } from './hooks/useUserData'
import Groups from '../groups/Groups'
import Group from '../group/Group'
import Profile from '../profile/Profile'
import { useTranslation } from 'react-i18next'
import { languageCode } from '../utils/functions/types'
import { changeUserLocale } from '../utils/functions/firebase/userManagement'

type Props = {
  user: User
  darkMode: boolean
  toggleDarkMode: () => void
}

function Main({ user, darkMode, toggleDarkMode }: Readonly<Props>) {
  const navigate = useNavigate()
  const location = useLocation()
  const { i18n } = useTranslation()
  const [languageSelected, setLanguageSelected] = useState<languageCode>(
    i18n.language as languageCode
  )
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const refMobileMenu = useRef(null)
  const [userData, groupsData] = useUserData(user)
  useEffect(() => {
    const handleAuthChange = (user: User) => {
      if (
        !user &&
        location.pathname !== '/auth/login' &&
        location.pathname !== '/auth/signup'
      )
        navigate('/auth/login')
    }
    onAuthStateChanged(auth, (user) => {
      handleAuthChange(user)
    })
  }, [location.pathname, navigate])

  const changeLanguage = useCallback(
    (language: languageCode) => {
      i18n.changeLanguage(language)
      setLanguageSelected(language)
      changeUserLocale(language)
    },
    [i18n]
  )

  useEffect(() => {
    if (userData && i18n.language !== userData?.locale) {
      changeLanguage(userData.locale as languageCode)
    }
  }, [i18n, userData, changeLanguage])
  const closeMenu = useCallback(() => {
    if (refMobileMenu.current) {
      refMobileMenu.current.style.transform = 'translateX(-100%)'
      document.getElementById('overlay').style.opacity = '0'
      setTimeout(() => {
        setIsMenuOpen(false)
      }, 200)
    }
  }, [])
  const toggleMobileMenu = useCallback(() => {
    if (!isMenuOpen) {
      setIsMenuOpen(true)
    } else closeMenu()
  }, [isMenuOpen, closeMenu])
  window.addEventListener('resize', () => {
    if (window.innerWidth > 768) closeMenu()
  })
  return (
    <div className="mx-auto w-full max-w-screen-xl px-4">
      <Navbar
        userData={userData}
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        languageSelected={languageSelected}
        setLanguageSelected={changeLanguage}
        mobileMenuHandler={toggleMobileMenu}
      />
      {isMenuOpen && (
        <MobileMenu
          userData={userData}
          languageSelected={languageSelected}
          setLanguageSelected={changeLanguage}
          forwardedRef={refMobileMenu}
          closeMenuHandler={toggleMobileMenu}
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
        />
      )}
      <Routes>
        <Route path="/" element={<Home userData={userData} />} />
        <Route path="/profile" element={<Profile userData={userData} />} />
        <Route path="/groups" element={<Groups groups={groupsData} />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/group/:id" element={<Group user={userData} />} />
      </Routes>
    </div>
  )
}

export default Main
