import LoadingWidget from './LoadingWidget'

type Props = {
  text?: string
}

function LoadingFullPage({ text }: Readonly<Props>) {
  return (
    <output className="flex h-[45svh] w-full flex-col items-center justify-end gap-4">
      {text && (
        <p className="text-center text-4xl font-medium text-light-secondary-2 dark:text-dark-secondary-2">
          {text}
        </p>
      )}
      <LoadingWidget size="12" />
      <span className="sr-only">{text || 'Loading...'}</span>
    </output>
  )
}

export default LoadingFullPage
